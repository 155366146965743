import { inject } from "mobx-react"
import React, { RefObject } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../../Api"
import AlertMessage from "../../components/AlertMessage"
import { SuccessMessage } from "../../components/SuccessMessage"
import { ManageVenuesLocation } from "../../Locations"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { trackEvent } from "../../tracking"
import trackingConstants from "../../tracking/trackingConstants"


export interface CreateOrganizationFormProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  api?: MerchantAdminApi
}

type CreateOrganizationFormState = {
  buttonPressed: boolean
  organizationCreated: boolean
  formData: { [index: string]: string }
}
 
const CREATE_ORGANIZATION_FORM_ERROR = "CreateOrganizationForm"

@inject("userSessionStore")
@inject("api")
class CreateOrganizationForm extends React.Component<CreateOrganizationFormProps, CreateOrganizationFormState> {
  formRef: RefObject<any>

  constructor(props: CreateOrganizationFormProps) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      buttonPressed: false,
      organizationCreated: false,
      formData: {
        name: "",
      },
    }
  }

  async onFormSubmit(e: any, formData: any, inputs: any) {
    e.preventDefault()
    this.setState({buttonPressed: true})
    try {
      const newOrganizations = [{
        "id": 'NEW__',
        "name": formData.name,
        "venues": []
      }]
      const createOrganizationResponse: any = await this.props.api!.createOrganization(newOrganizations, CREATE_ORGANIZATION_FORM_ERROR)
      if (!createOrganizationResponse) {
        return
      } else {
        this.props.userSessionStore?.addOrganizationAndSetAsSelected(createOrganizationResponse.organizations[0])
      }

      this.setState({
        organizationCreated: true,
        formData: {
          name: "",
        },
      })
      
      this.props.history.push(ManageVenuesLocation.toUrl(this.props.userSessionStore!.selectedBranch!.urlFields()))
    } finally {
      this.setState({buttonPressed: false})
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    this.setState(prevState => ({
      organizationCreated: false,
      formData: {
        ...prevState.formData,
        [target.name]: target.value,
      },
    }))
  }

  render() {
    const {t} = this.props

    return (
      <>
        <SuccessMessage show={this.state.organizationCreated} message={t("superAdminScreen.addNewOrganization.successMessage")}/>
        <AlertMessage source={CREATE_ORGANIZATION_FORM_ERROR}/>

        <ValidationForm onSubmit={this.onFormSubmit.bind(this)} ref={this.formRef}>
          <fieldset disabled={this.state.buttonPressed}>
            <Row className="form-group">
              <Col md={12} className="text-left">
                <label className="text-primary" htmlFor="name">{t("superAdminScreen.addNewOrganization.organizationName")}</label>
                <TextInput name="name" id="name" required
                           type="name"
                           autoComplete="off"
                           value={this.state.formData.name}
                           errorMessage={{
                             validator: t("validationErrorPassphrase"),
                             type: t("validationErrorName"),
                             required: t("fieldRequired")
                           }}
                           onChange={this.handleChange}/>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={6}>
                <button ref="createOrganizationButton"
                        className="btn-block btn btn-primary font-weight-bold">
                          {t("superAdminScreen.addNewOrganization.createButton")}</button>
              </Col>
            </Row>
          </fieldset>
        </ValidationForm>
      </>
    )
  }

}


export interface SuperAdminScreenProps {
}

class CreateOrganizationScreen extends React.Component {
  componentDidMount(): void {
    trackEvent(trackingConstants.screens.profile)
  }

  render() {

    const ExtendedCreateOrganizationForm = withRouter(withTranslation()(CreateOrganizationForm))

    return (
      <>      
        <Container>
          <Row className="justify-content-center">
            <Col sm={6}>
              <ExtendedCreateOrganizationForm/>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default CreateOrganizationScreen
