import {ApiResponse} from "apisauce"

export interface ApiResult {
  modified: boolean
  payload: any
  etag: string
}

export function getApiResult(response: ApiResponse<any>): ApiResult {
  // @ts-ignore
  return { modified: response.status !== 304, payload: response.data, etag: response.status === 304 ? response.config?.headers["If-Match"] : response.headers["etag"] } as ApiResult
}
