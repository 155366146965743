// @flow strict

import {ApiResponse, ApisauceInstance, create} from "apisauce"
import {AxiosRequestConfig} from "axios"
import {IUserSessionStore} from "./models/UserSessionStore"
import {ApiResult, getApiResult} from "./ApiResult";
import md5 from "blueimp-md5"
import { FETCH_MEMBERS_COLUMNS } from "./models/MemberStore";

export const FETCH_MEMBERS_SORTING = {
  JOINED: 'joined',
  PURCHASES: 'purchases',
  BENEFITS: 'benefits',
  CARDS: 'cards',
  NAME: 'name',
  STAMP_CARDS_LIST: 'stampCardsList',
  STAMP_CARDS_TOTAL_STAMPS: 'stampCardsTotalStamps',
  STAMP_CARDS_TOTAL_REWARDS: 'stampCardsTotalRewards'
};

export const FETCH_MEMBERS_ORDERING = {
  ASC: 'asc',
  DESC: 'desc'
};

export type FetchMembersSortingTypes = typeof FETCH_MEMBERS_SORTING[keyof typeof FETCH_MEMBERS_SORTING];
export type FetchMembersOrderingTypes = typeof FETCH_MEMBERS_ORDERING[keyof typeof FETCH_MEMBERS_ORDERING];

export interface FetchMembersParams {
  organization?: string;
  members?: Array<string>;
  requestSource?: string;
  page?: number;
  sorting?: FetchMembersSortingTypes;
  ordering?: FetchMembersOrderingTypes;
  name?: string;
  venueStatuses?: string[];
  disableCache?: boolean;
  columns?: Array<FETCH_MEMBERS_COLUMNS>;
  stampCardsIds?: Array<string>;
}


function isResponseSuccessful(response: ApiResponse<any>) {
  return (response.ok || response.status === 304)
}

function getRequestString(requestConfig: AxiosRequestConfig) {
  const method = requestConfig.method || '-'
  const uri = requestConfig.url?.substring(requestConfig.baseURL?.length!) || '-'
  const params = JSON.stringify(requestConfig.params)
  const data = typeof requestConfig.data === "string" ? requestConfig.data : JSON.stringify(requestConfig.data)
  return method + uri + md5(method + uri + params + data)
}

export class MerchantAdminApi {
  client: ApisauceInstance
  userSessionStore: IUserSessionStore
  baseURL: string

  constructor(baseURL: string, userSessionStore: IUserSessionStore) {
    this.userSessionStore = userSessionStore
    this.baseURL = baseURL

    this.client = create({
      baseURL: baseURL,
      withCredentials: true,
    })

    this.client.addRequestTransform(this._addHeaders.bind(this))
  }

  getPartnerEmailCSVUrl(organization: string) {
    return `${this.baseURL}/org/${organization}/members/email/csv_export`
  }

  _returnApiResult(response: ApiResponse<any>, disableCache = false) {
    if (isResponseSuccessful(response)) {
      // @ts-ignore
      if (!disableCache && response.headers && response.headers.etag) {
        // @ts-ignore
        const etag = response.headers!.etag
        this.userSessionStore.setRequestEtag(getRequestString(response.config!), etag)
      }
      return getApiResult(response)
    } else {
      return Promise.reject()
    }
  }

  async _addHeaders(request: AxiosRequestConfig) {
    const csrfToken = this.userSessionStore.getCSRFToken()
    if (csrfToken) {
      request.headers["CSRFToken"] = csrfToken
    }

    const requestHash = getRequestString(request)
    const etag = this.userSessionStore.getRequestEtag(requestHash)
    if (etag) {
      request.headers["If-Match"] = etag
    }
  }

  _handleResponseErrors(requestSource: string | undefined, response: ApiResponse<any>): ApiResponse<any> {
    if (!response.ok && response.status !== 304) {
      this.userSessionStore.setAlertMessage(this._getAlertMessage(response), requestSource)
    } else {
      this.userSessionStore.clearAlertMessage()
    }
    return response
  }

  _getAlertMessage(response: ApiResponse<any>) {
    if (response.problem === "NETWORK_ERROR") {
      return "networkErrorMsg"
    } else if (response.status === 401) {
      this.userSessionStore.logOut()
      return "pleaseLoginAgainMsg"
    } else if (response.status === 403) {
      return "invalidUsernameOrPassphrase"
    } else if (response.status === 422) {
      return response.data.error ? response.data.error : "systemErrorMsg"
    } else {
      return "tempSystemErrorMsg"
    }
  }

  async login(email: string, passphrase: string, requestSource: string | undefined = undefined): Promise<boolean> {
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post("/auth/login", {"email": email, "passphrase": passphrase}),
    )

    if (response.ok) {
      // @ts-ignore
      this.userSessionStore.setCSRFToken(response.data.data.csrfToken)
      return true
    } else {
      return false
    }
  }

  async signup(passphrase: string, invitationKey: string, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post("/auth/signup_with_key", {
        "passphrase": passphrase,
        "invitationKey": invitationKey,
      }),
    )
  }

  async resetPasswordRequest(email: string, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post("/auth/reset_password_token", {
        "email": email,
      }),
    )
  }

  async resetPassword(resetToken: string, password: string, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post("/auth/reset_password", {
        "resetToken": resetToken,
        "passphrase": password,
      }),
    )
  }

  async changePassword(currentPassword: string, newPassword: string, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post("/auth/change_password", {
        "currentPassphrase": currentPassword,
        "newPassphrase": newPassword,
      }),
    )
  }

  async sendAdminInvite(email: string, fullName: string, organization: string, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post(`/admin/org/${organization}/inviteUser`, {
        "email": email,
        "name": fullName,
      }),
    )
  }

  async createOrganization(organizations: any[], requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/admin/organizations/`, {
        "organizations": organizations,
      }),
    )

    return response.data?.data
  }

  async updateIsPremium(organizationId: string, isPremium: boolean, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    return this._handleResponseErrors(
      requestSource,
      await this.client.post(`/admin/organization/premium`, {
        "organizationId": organizationId,
        "isPremium": isPremium,
      }),
    )
  }

  async refreshLoginStatus(initSession: boolean = false): Promise<boolean> {
    const response = await this.client.post("/auth/session")
    if (response.ok) {
      // @ts-ignore
      this.userSessionStore.logIn(response.data.data, initSession)
    } else {
      this.userSessionStore.logOut()
    }

    return response.ok
  }

  async logout(): Promise<ApiResponse<any>> {
    return await this.client.post("/auth/logout")
  }

  async fetchEmailOctopusIntegrations(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/integrations/`),
    )
    return this._returnApiResult(response)
  }

  async fetchAvailableEmailOctopusIntegrations(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/enabled-integrations/`),
    )
    return this._returnApiResult(response)
  }

  async fetchProducts(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/products/`),
    )
    return this._returnApiResult(response)
  }

  async fetchLoyaltyCardStats(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
  
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/loyalty-cards/statistics`),
    )
    return this._returnApiResult(response)
  }  

  async fetchRewards(organization = undefined, requestSource = undefined) {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id;
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/rewards/`),
    )

    return this._returnApiResult(response)
  }



  async fetchRewardsTriggers(organization = undefined, requestSource = undefined) {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id;
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/rewards/triggers/`),
    )
    return this._returnApiResult(response)
  }

  async fetchSubscriptionsReport(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/subscriptions/report/v1`),
    )
    return this._returnApiResult(response)
  }

  async fetchSubscriptions(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/subscriptions/`),
    )
    return this._returnApiResult(response)
  }

  async fetchMemberTimeline(organization: string | undefined = undefined, memberId: string, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/members/${memberId}/timeline`),
    )
    return this._returnApiResult(response)
  }

  async fetchBenefits(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/benefits/`),
    )
    return this._returnApiResult(response)
  }

  async fetchVenue(organization: string | undefined = undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id


    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/data`),
    )

    return this._returnApiResult(response, disableCache)
  }

  async fetchVenueStats(organization: string | undefined = undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id


    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/members/stats`),
    )

    return this._returnApiResult(response, disableCache)
  }

  async fetchVenueReferralCodes(organization: string | undefined = undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id


    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/referral_codes`),
    )

    return this._returnApiResult(response, disableCache)
  }


  async fetchMessageRecipientCount({
    organization,
    configuration,
    disableCache
  }: {organization: string | undefined, configuration: any, disableCache: boolean}): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
   
    const response = await this.client.post(`/org/${requestOrganization}/messages/count_check/`, { configuration });
    return this._returnApiResult(response, disableCache)
  }

  async fetchMembers(params: FetchMembersParams): Promise<ApiResult> {
    const {
      organization = undefined,
      members = undefined,
      requestSource = undefined,
      page = undefined,
      sorting = undefined,
      ordering = undefined,
      name = undefined,
      venueStatuses = [],
      disableCache = false,
      columns = [],
      stampCardsIds = []
    } = params;

    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
    const isNotOrganizationContext = !this.userSessionStore!.isOrganizationContext()
    const queryParams: any = {}
  
    if (members) {
      queryParams.members = JSON.stringify(members)
    }
  
    if (page) {
      queryParams.page = page
    }

    if (ordering) {
      queryParams.ordering = ordering;
    }
  
    if (sorting) {
      queryParams.sorting = sorting
    }

    if (name) {
      queryParams.name = name
    }

    if (isNotOrganizationContext) {
      queryParams.venue = this.userSessionStore!.selectedBranch?.id
    }

    let arrayParams = '';
    const filterParams = [
      ...venueStatuses.map(id => `venueStatus=${id}`),
      ...columns.map(column => `column=${column}`),
      ...stampCardsIds.map(id => `stampCard=${id}`)
    ];
  
    if (filterParams.length > 0) {
      arrayParams = '?' + filterParams.join('&');
    }

    try {
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/members/list${arrayParams}`, queryParams)
    )
  
      return this._returnApiResult(response, disableCache)
    } catch (error) {
      console.log('members/list error ', error)
      return Promise.reject(error)
    }
  }

  async fetchMessages(organization: string | undefined = undefined, messages: Array<any> | undefined = undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/messages/`, messages ? {data: JSON.stringify(messages)} : {}),
    )
    return this._returnApiResult(response, disableCache)
  }

  async fetchInvitations(organization: string | undefined = undefined, messages: Array<any> | undefined = undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
 
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/org_members/`),
    )
    return this._returnApiResult(response, disableCache)
  }

  async addMemberVenueStatus(memberId: string, status: { id: string, validFrom: string, validThrough: string }, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/members/profile/${memberId}/venue_status`, status),
    )

    return response.ok
  }

  async updateMemberVenueStatusExternalIdentifier(userVenueStatusId: string | null, externalIdentifier: string | null, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    if (!userVenueStatusId) {
      return false;
    }
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id;

    const payload = {
        externalIdentifier: externalIdentifier,
    };

    const response = this._handleResponseErrors(
        requestSource,
        await this.client.post(`/org/${requestOrganization}/members/user_venue_status/${userVenueStatusId}/external_identifier`, payload),
    );

    return response.ok; 
  }


  async removeMemberVenueStatus(memberId: string, venueStatusId: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.delete(`/org/${requestOrganization}/members/profile/${memberId}/venue_status`, {}, {data: {id: venueStatusId}},),
    )

    return response.ok
  }

  async removeMember(memberId: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.delete(`/org/${requestOrganization}/${memberId}/remove_member`),
    )

    return response.ok
  }



  async fetchEventsIndex(organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<ApiResponse<any>> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/events/index`),
    )

    if (response.ok) {
      return response.data
    } else {
      return Promise.reject()
    }
  }

  async fetchEvents(organization: string | undefined = undefined, params: { range: any }, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/events/`, params),
    )

    return this._returnApiResult(response)
  }

  async fetchEventsReports(organization: string | undefined = undefined, ids: Array<string> | undefined, requestSource: string | undefined = undefined): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/events/report/v2`, {events: ids || []}),
    )

    return this._returnApiResult(response)
  }

  async updateEvents(events: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/events/`, {events: events}),
    )

    return response.ok
  }

  async uploadImage(imageType: string, imageFile: any, requestSource: string | undefined = undefined): Promise<string | undefined> {
    const organizationID = this.userSessionStore.currentOrganization!.id

    const data = new FormData();

    data.append('imageFile', imageFile);

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${organizationID}/upload/image/${imageType}`, data, {
        headers: {
          'Content-Disposition': 'form-data',
          "Content-Type": 'multipart/form-data'
        }
      }),
    )

    return response.data?.data?.imageUrl
  }

  async fetchBenefitReports(organization: string | undefined = undefined, dateRange: { startDate: Date, endDate: Date } | undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
 
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.get(`/org/${requestOrganization}/benefits/report/v1`, dateRange),
    )
    return this._returnApiResult(response, disableCache)
  }

  async fetchProductReports(organization: string | undefined = undefined, dateRange: { startDate: Date, endDate: Date } | undefined, requestSource: string | undefined = undefined, disableCache = false): Promise<ApiResult> {
    try {
      const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

      const response = this._handleResponseErrors(
        requestSource,
        await this.client.get(`/org/${requestOrganization}/products/report/v1`, dateRange),
      )
      return this._returnApiResult(response, disableCache)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async updateBenefits(benefits: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    console.log('benefits to update', benefits)

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/benefits/`, {benefits: benefits}),
    )

    return response.ok
  }

  async postProducts(products: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/products/`, {products: products}),
    )

    return response.data?.data
  }

  async postReward(reward: any, organization: string | undefined = undefined, requestSource: string | undefined = undefined) {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id;
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/rewards/`, reward)
    )
    return response.data?.data
  }

  async postStatuses(statuses: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/statuses/`, {statuses: statuses}),
    )

    return response.data?.data
  }

  async postSubscriptions(subscriptions: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/subscriptions/`, {statuses: subscriptions}),
    )

    return response.data?.data
  }

  async postVenues(venues: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/venues/`, {venues: venues}),
    )

    return response.data?.data
  }

  async postMessages(messages: Array<any>, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/messages/`, {messages: messages}),
    )

    return response.data?.data
  }

  async deleteMessage(messageId: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id
  
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.delete(`/org/${requestOrganization}/messages/${messageId}`),
    )
  
    return response.ok
  }
  

  async sendInvitation(email: string, name: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/send_invite`, {email: email, name: name}),
    )

    return response.data?.data
  }

  async generateInvitationCode(statusId: string | null, venueId: string | null, codeId: string | null, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id;
  
    const body: any = {};
    if (codeId) {
      body.codeId = codeId;
      body.statusId = null;
      body.venueId = null;
    } else {
      body.codeId = null;
      body.statusId = statusId;
      body.venueId = venueId;
    }
  
    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/generate_referral_code`, body),
    );
  
    return response.data?.data;
  }
  

  async resendInvitation(invitedUserId: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/${invitedUserId}/resend_invite`),
    )

    return response.data?.data
  }

  async postEmailOctopusIntegration(apiKey: string, integrationId: string, advanced: boolean, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<any> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/integrations/`, {
        integration_id: integrationId,
        credentials: {
          apikey: apiKey
        },
        advanced: advanced
      }),
    )

    return response.data?.data
  }

  async sendSimplePushNotificationRequest(notificationText: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<boolean> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/members/push/simple`, {notificationText: notificationText}),
    )

    return response.ok
  }

  async checkVenueHandle(venueHandle: string, organization: string | undefined = undefined, requestSource: string | undefined = undefined): Promise<string> {
    const requestOrganization = organization ? organization : this.userSessionStore.currentOrganization!.id

    const response = this._handleResponseErrors(
      requestSource,
      await this.client.post(`/org/${requestOrganization}/venue/verifyHandle`, {handle: venueHandle}),
    )

    return response.data?.data?.result
  }

}
