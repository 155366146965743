import { types, flow, getEnv, Instance, SnapshotIn } from "mobx-state-tree";
import { LoyaltyCard, ILoyaltyCard } from "./LoyaltyCard";
import { ApiResult } from "../ApiResult";

type VenueData = SnapshotIn<typeof VenueDataModel>;
const VenueDataModel = types.model({
  id: types.string,
  check_balances: types.integer,
  register_purchases: types.integer,
  cards: types.array(LoyaltyCard)
});

export const LoyaltyCardStore = types.model("LoyaltyCardStore")
  .props({
    loyaltyCards: types.optional(types.array(LoyaltyCard), []),
  })
  .actions(self => ({
    fetchData: flow(function* fetchData(requestSource: string = "init") {

      try {
        const response: ApiResult = yield getEnv(self).api.fetchLoyaltyCardStats(undefined, requestSource);
        console.log('fetchLoyaltyCardStats response', response)
        if (response.modified) {
          const transformedCards: ILoyaltyCard[] = response.payload.data.data.reduce(
            (acc: any[], venueData: VenueData) => {
              const venueCards = venueData?.cards?.map(card => ({
                ...card,
                restaurantId: venueData.id
              }));
              return acc.concat(venueCards);
            }, []);
          self.loyaltyCards.replace(transformedCards);
        }
        return response.etag;
  
      } catch (error) {
        console.error('Error fetching loyalty card data:', error);
      }
    }),
    resetData() {
      self.loyaltyCards.clear();
    },
    addLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      self.loyaltyCards.push(loyaltyCard);
    },
    updateLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      const index = self.loyaltyCards.findIndex(lc => lc.id === loyaltyCard.id);
      if (index !== -1) {
        self.loyaltyCards[index] = loyaltyCard;
      }
    },
    removeLoyaltyCard(loyaltyCard: ILoyaltyCard) {
      self.loyaltyCards.remove(loyaltyCard);
    }
  }));

export type ILoyaltyCardStore = Instance<typeof LoyaltyCardStore>;
