import {match, Prompt, RouteComponentProps, withRouter} from "react-router"
import {IUserSessionStore} from "../models/UserSessionStore"
import {inject, observer} from "mobx-react"
import React from "react"
import {withTranslation, WithTranslation} from "react-i18next"
import {trackEvent} from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import {TextInput, ValidationForm} from "react-bootstrap4-form-validation"
import {IEventStore} from "../models/EventStore"
import AlertMessage from "../components/AlertMessage"
import {IEventReportStore} from "../models/EventReportStore"
import validator from "validator"
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/Dropdown"
import Button from "react-bootstrap/Button"
import Check from "react-bootstrap/FormCheck"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {clone, getSnapshot, resolveIdentifier} from "mobx-state-tree"
import {EventsLocation} from "../Locations"
import {Event, EVENT_PROPERTIES_TICKET_QUOTA, EventStatus, IEvent} from "../models/Event"
import {IVenue, Venue} from "../models/Venue"
import moment from "moment-timezone"
import {autorun} from "mobx"
import Card from "react-bootstrap/Card"
import {fi} from "date-fns/esm/locale"
import i18n from "../i18n";
import {getModifications} from "../utils/objectModifications";
import {IRootStore} from "../models/RootStore";
import {MerchantAdminApi} from "../Api";
import {ModalBox} from "../components/ModalBox"
import Modal from "react-bootstrap/Modal"
import log from "loglevelnext";
import * as _ from "lodash";
import ImageUploadModal from "../components/ImageUploadModal"
import { urlToFile } from "../utils"
import Skeleton from "react-loading-skeleton"
import { colors } from "../theme/colors"

registerLocale('fi', fi)

const EVENT_MODIFY_SCREEN_ERROR = "eventModifyScreen"
const NEW_EVENT_ID_PREFIX = "NEW__"


const EVENT_TYPE_PAID = "paidEvent"
const EVENT_TYPE_FREE = "freeEvent"

const EVENT_STATUS_PAID_NORMAL = "normal"
const EVENT_STATUS_PAID_SOLDOUT = "soldOut"
const EVENT_STATUS_PAID_NOT_SOLD = "notSold"
const EVENT_STATUS_HIDDEN = "hidden"

const EVENT_PRICING_ITEM_ENTRANCE = "entrance"
const EVENT_PRICING_ITEM_CLOAKROOM = "cloakroom"

const EVENT_ITEM_PRICING_AMOUNT = "amount"
const EVENT_ITEM_PRICING_VAT_PERCENTAGE = "vat_percentage"

const EVENT_PRICING_DEFAULT_VAT_ENTRANCE = "10"
const EVENT_PRICING_DEFAULT_VAT_CLOAKROOM = "24"

interface matchParam {
  id: string,
  eventId?: string
  createBasedOn?: string
}

const IMAGE_UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  ERROR: "ERROR"
}

export interface EventsModifyScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  eventStore?: IEventStore
  eventReportStore?: IEventReportStore
  rootStore?: IRootStore
  api?: MerchantAdminApi
  match: match<matchParam>
}

export interface EventItemPricing {
  [item: string]: { amount: string, vat_percentage: string }
}

export interface EventsModifyScreenState {
  buttonPressed: boolean
  startDate: Date,
  endDate: Date,
  pricing: EventItemPricing,
  type: string,
  status: string,
  editableEvent: IEvent,
  originalEvent: IEvent,
  venue: IVenue,
  edited: boolean,
  showNavigationConfirmation: boolean,
  enableTicketQuota: boolean,
  showUploadPhotoModal: boolean,
  selectedImage: string,
  croppedImage: string,
  uploadedImageURL: string,
  imageURL: string
}

class CustomDatePicker extends React.Component<{ onClick?: any, value?: any }> {
  render() {
    const {onClick, value} = this.props
    return (
      <Button className="mr-2" variant="secondary" onClick={onClick}><FontAwesomeIcon className="mr-2"
                                                                                              icon={["fal", "calendar-lines"]}/>{value}
      </Button>)
  }
}

class CustomTimePicker extends React.Component<{ onClick?: any, value?: any }> {
  render() {
    const {onClick, value} = this.props
    return (
      <Button className="mr-2" variant="secondary" onClick={onClick}><FontAwesomeIcon className="mr-2"
                                                                                              icon={"clock"}/>{value}
      </Button>
    )
  }
}


function formatIntegerToDecimalValue(value: number) {
  return _.round(value / 100, 2).toString().replace(".", i18n.languages[0] === "en" ? "." : ",")
}

function formatDecimalValue(value: number) {
  return _.round(value, 2).toString().replace(".", i18n.languages[0] === "en" ? "." : ",")
}

function getEventModifyStatus(event: IEvent) {
  return event.isPaymentDisabled() ? EVENT_STATUS_PAID_NOT_SOLD
    : event.isHidden() ? EVENT_STATUS_HIDDEN
      : event.isSoldOut() ? EVENT_STATUS_PAID_SOLDOUT
        : EVENT_STATUS_PAID_NORMAL
}

function getEventModifyType(event: IEvent) {
  return !event.isFree() ? EVENT_TYPE_PAID : EVENT_TYPE_FREE
}

function getDefaultVAT(item: string) {
  return item === EVENT_PRICING_ITEM_ENTRANCE ? EVENT_PRICING_DEFAULT_VAT_ENTRANCE : EVENT_PRICING_DEFAULT_VAT_CLOAKROOM
}

function getEventModifyItemPricing(event: IEvent, item: string) {
  return {
    amount: event.pricing.has(item) ? formatIntegerToDecimalValue(event.pricing.get(item)!.amount) : "0",
    vat_percentage: event.pricing.has(item) ? formatDecimalValue(event.pricing.get(item)!.vat_percentage!) : getDefaultVAT(item)
  }
}

const decimalValidator = (value: string) => validator.isDecimal(value, {
  decimal_digits: "0,2",
  locale: i18n.languages[0] === "en" ? "en-US" : "sv-SE"
})


const eventPricingNumberValidator = (maxValue: number | undefined = undefined) => (price: string) =>
  decimalValidator(price) &&
  validator.isFloat(price.replace(",", "."), {min: 0, ...maxValue ? {max: maxValue} : {}})


function getInitialEventModifyState(event: IEvent) {
  return {
    editableEvent: clone(event),
    buttonPressed: false,
    startDate: new Date(moment.utc(event.startTime).toString()),
    endDate: new Date(moment.utc(event.endTime).toString()),
    pricing: {
      [EVENT_PRICING_ITEM_ENTRANCE]: getEventModifyItemPricing(event, EVENT_PRICING_ITEM_ENTRANCE),
      [EVENT_PRICING_ITEM_CLOAKROOM]: getEventModifyItemPricing(event, EVENT_PRICING_ITEM_CLOAKROOM)
    },
    type: getEventModifyType(event),
    status: getEventModifyStatus(event),
    edited: false,
    enableTicketQuota: event.hasTicketQuota(),
    showNavigationConfirmation: true
  }
}

@(withRouter as any)
@inject("userSessionStore")
@inject("eventReportStore")
@inject("eventStore")
@inject("rootStore")
@inject("api")
@observer
class EventsModifyScreen extends React.Component<EventsModifyScreenProps, EventsModifyScreenState> {
  modificationAutorunDisposer: any
  beforeUnloadBind: any
  formRef: any = React.createRef();

  constructor(props: EventsModifyScreenProps) {
    super(props)
    const {match: {params: {id, eventId, createBasedOn}}} = this.props

    let venue: IVenue
    let originalEvent: IEvent

    if (createBasedOn === 'true' && eventId) {
      originalEvent = resolveIdentifier(Event, this.props.eventStore!.events, eventId) as IEvent
      venue = resolveIdentifier(Venue, this.props.userSessionStore!.organizations, originalEvent.restaurantId.id) as IVenue

      const eventPricing = {
        entrance: {
          amount: originalEvent.pricing.get(EVENT_PRICING_ITEM_ENTRANCE)?.amount || 0,
          vat_percentage: originalEvent.pricing.get(EVENT_PRICING_ITEM_ENTRANCE)?.vat_percentage || 0
        },
        cloakroom: {
          amount: originalEvent.pricing.get(EVENT_PRICING_ITEM_CLOAKROOM)?.amount || 0,
          vat_percentage: originalEvent.pricing.get(EVENT_PRICING_ITEM_CLOAKROOM)?.vat_percentage || 0
        }
      }

      originalEvent = Event.create({
        id: NEW_EVENT_ID_PREFIX + "1",
        restaurantId: venue.id,
        paymentEnabled: originalEvent.paymentEnabled,
        title: originalEvent.title,
        description: originalEvent.description,
        startTime: originalEvent.startTime,
        endTime: originalEvent.endTime,
        pricing: eventPricing,
        status: originalEvent.status === EventStatus.NORMAL_SOLDOUT ? EventStatus.NORMAL : originalEvent.status,
        images: {
          mainImage: originalEvent.images?.mainImage 
        },
        properties: {
          "url": originalEvent.properties.get("url") || null,
          "facebookEventUrl": originalEvent.properties.get("facebookEventUrl") || null,
          "ticketQuotaVisitors":  originalEvent.properties.get("ticketQuotaVisitors") || null
        },
        created_at: moment.utc().toISOString(),
        edited_at: moment.utc().toISOString(),
      })
    } else if (!eventId) {
      const date = moment().set({"second": 0, "minutes": 0, "hours": 22})
      venue = resolveIdentifier(Venue, this.props.userSessionStore!.organizations, id) as IVenue
      originalEvent = Event.create({
        id: NEW_EVENT_ID_PREFIX + "1",
        status: EventStatus.NORMAL,
        restaurantId: venue.id,
        paymentEnabled: true,
        title: "",
        description: "",
        startTime: date.utc().toISOString(),
        endTime: date.add(1, "days").hours(3).utc().toISOString(),
        pricing: {
          entrance: {
            amount: 0,
            vat_percentage: 10,
          },
          cloakroom: {
            amount: 0,
            vat_percentage: 24,
          },
        },
        properties: {
          "url": null,
          "facebookEventUrl": null,
        },
        created_at: moment.utc().toISOString(),
        edited_at: moment.utc().toISOString(),
      })
    } else {
      // @ts-ignore
      originalEvent = resolveIdentifier(Event, this.props.eventStore!.events, eventId) as IEvent
      venue = resolveIdentifier(Venue, this.props.userSessionStore!.organizations, originalEvent.restaurantId.id) as IVenue
    }

    this.state = {
      originalEvent: originalEvent,
      venue: venue,
      showUploadPhotoModal: false,
      uploadedImageURL: originalEvent?.images?.mainImage || '',
      imageURL: '',
      selectedImage: '',
      croppedImage: '',
      ...getInitialEventModifyState(originalEvent)
    }
  }

  beforeUnload(this: this, event: any) {
    if (this.state.edited) {
      event.returnValue = "block"
    }
  }

  componentDidMount(): void {
    trackEvent(trackingConstants.screens.eventsModify, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
    this.registerModificationAutorun(this.state.originalEvent, this.state.editableEvent)
    this.beforeUnloadBind = this.beforeUnload.bind(this)
    window.addEventListener('beforeunload', this.beforeUnloadBind)
  }

  registerModificationAutorun(originalEvent: IEvent, editableEvent: IEvent) {
    if (this.modificationAutorunDisposer) this.modificationAutorunDisposer()
    this.modificationAutorunDisposer = autorun(() => {
      const edited = this.hasModifications(originalEvent, editableEvent)
      this.setState({
        edited: edited,
      })
    })
  }

  componentWillUnmount(): void {
    this.modificationAutorunDisposer()
    window.removeEventListener("beforeunload", this.beforeUnloadBind)
  }

  componentDidUpdate(prevProps: Readonly<EventsModifyScreenProps>) {
    log.debug("EventsModifyScreen updated")
  }

  hasModifications(originalEvent: IEvent, editableEvent: IEvent): boolean {
    // @ts-ignore
    return !_.isEqual(
      _.omit(getSnapshot(originalEvent), "edited_at"),
      _.omit(getSnapshot(editableEvent), "edited_at"),
    )
  }

  async saveModifications() {
    const {api} = this.props
    const {editableEvent, originalEvent} = this.state

    let zeroAmounts: Array<string> = []
    this.state.editableEvent.pricing.forEach((value, key) => {
      if (value.amount === 0) zeroAmounts.push(key)
    })
    this.state.editableEvent.removePricingObjects(zeroAmounts)

    // @ts-ignore
    const modifications: Array<any> = [getSnapshot(editableEvent)]
    const original: Array<any> = !originalEvent.id.startsWith(NEW_EVENT_ID_PREFIX) ? [getSnapshot(originalEvent)] : []
    const apiRequest = getModifications(modifications, original)
    const updateResult = await api!.updateEvents(apiRequest, undefined, EVENT_MODIFY_SCREEN_ERROR)

    if (updateResult) {
      await this.props.rootStore!.fetchData(false, false, true)
      this.props.history.push(EventsLocation.toUrl(this.props.userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  async resetEditing() {
    const originalEvent = this.state.originalEvent
    this.setState({
      ...getInitialEventModifyState(originalEvent)
    })
  }

  handleStartDateSelect = (date: Date) => {
    if (moment(date).utc().isAfter(moment(this.state.endDate).utc())) {
      this.setState({endDate: moment(date).utc().toString(), startDate: moment(date).utc().toString()})
      this.state.editableEvent.setEndTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
      this.state.editableEvent.setStartTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
    } else {
      this.setState({startDate: moment(date).utc().toString()})
      this.state.editableEvent.setStartTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
    }
  }

  handleEndDateSelect = (date: Date) => {
    if (moment(date).utc().isBefore(moment(this.state.startDate).utc())) {
      this.setState({endDate: moment(date).utc().toString(), startDate: moment(date).utc().toString()})
      this.state.editableEvent.setEndTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
      this.state.editableEvent.setStartTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
    } else {
      this.setState({endDate: moment(date).utc().toString()})
      this.state.editableEvent.setEndTime(moment(moment(date).tz(this.state.venue.timezone, true)).utc().toISOString())
    }
  }

  handleTypeSelect = (eventKey: any, event: Object) => {
    if (eventKey === EVENT_TYPE_PAID) {
      if (this.state.editableEvent.isFree()) {
        this.state.editableEvent.setPaymentEnabled(true)
        this.state.editableEvent.setPricing({
          ...Object.fromEntries(this.state.editableEvent.pricing),
          [EVENT_PRICING_ITEM_ENTRANCE]: {amount: 0, vat_percentage: parseFloat(EVENT_PRICING_DEFAULT_VAT_ENTRANCE)},
          [EVENT_PRICING_ITEM_CLOAKROOM]: {amount: 0, vat_percentage: parseFloat(EVENT_PRICING_DEFAULT_VAT_CLOAKROOM)},
        })
        this.setState({
          pricing: {
            ...this.state.pricing,
            [EVENT_PRICING_ITEM_ENTRANCE]: {amount: "0", vat_percentage: EVENT_PRICING_DEFAULT_VAT_ENTRANCE},
            [EVENT_PRICING_ITEM_CLOAKROOM]: {amount: "0", vat_percentage: EVENT_PRICING_DEFAULT_VAT_CLOAKROOM},
          },
        })
      }
    } else {
      this.state.editableEvent.setIsFree()
      this.setState({
        enableTicketQuota: false
      })
    }
    this.setState({type: eventKey})
  }

  handleStatusSelect = (eventKey: any, event: any) => {
    switch (eventKey) {
      case EVENT_STATUS_PAID_NORMAL:
        this.state.editableEvent.setStatus(EventStatus.NORMAL)
        this.state.editableEvent.setPaymentEnabled(true)
        if (this.state.type === EVENT_TYPE_PAID) {
          this.state.editableEvent.setPricing({
            [EVENT_PRICING_ITEM_ENTRANCE]: {amount: 0, vat_percentage: parseFloat(EVENT_PRICING_DEFAULT_VAT_ENTRANCE)},
            [EVENT_PRICING_ITEM_CLOAKROOM]: {
              amount: 0,
              vat_percentage: parseFloat(EVENT_PRICING_DEFAULT_VAT_CLOAKROOM)
            },
            ...Object.fromEntries(this.state.editableEvent.pricing),
          })
        } else {
          this.state.editableEvent.setIsFree()
        }
        break
      case EVENT_STATUS_PAID_NOT_SOLD:
        this.state.editableEvent.setIsPaymentDisabled()
        break
      case EVENT_STATUS_PAID_SOLDOUT:
        this.state.editableEvent.setIsSoldOut()
        break
      case EVENT_STATUS_HIDDEN:
        if (event === true) {
          this.state.editableEvent.setIsHidden()
          this.setState({status: eventKey})
        } else {
          this.state.editableEvent.setStatus(EventStatus.NORMAL)
          if (this.state.editableEvent.isFree()) {
            this.setState({type: EVENT_TYPE_FREE})
          } else {
            this.setState({type: EVENT_TYPE_PAID})
          }
          this.setState({status: EVENT_STATUS_PAID_NORMAL})
        }
        break
    }

    if (eventKey !== EVENT_STATUS_HIDDEN) {
      this.setState({status: eventKey})
    }
  }


  updatePricingField(item: string, attribute: string, value: string, convertToInt: boolean = true, maxValue: number | undefined = undefined) {
    if (value !== "" && !eventPricingNumberValidator(maxValue)(value)) return

    this.setState({
      pricing: {
        ...this.state.pricing,
        [item]: {
          ...this.state.pricing[item],
          [attribute]: value
        }
      }
    })

    if (eventPricingNumberValidator(maxValue)(value)) {
      const formattedValue = convertToInt ? _.round(parseFloat(value.replace(',', '.')) * 100, 0) : parseFloat(value.replace(',', '.'))
      this.state.editableEvent.setPricing({
        ...Object.fromEntries(this.state.editableEvent.pricing),
        [item]: {
          ...{[EVENT_ITEM_PRICING_AMOUNT]: 0, [EVENT_ITEM_PRICING_VAT_PERCENTAGE]: parseFloat(getDefaultVAT(item))},
          ...this.state.editableEvent.pricing.has(item) ? this.state.editableEvent.pricing.get(item) : {},
          [attribute]: formattedValue
        }
      })
    }
  }

  handleUploadImage = async (imageBlob: any) => {
    this.setState({uploadedImageURL: IMAGE_UPLOAD_STATUS.UPLOADING})
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await this.props.api!.uploadImage('venue/event/main', imageFile)
    if (imageURL) {
      this.setState({uploadedImageURL: imageURL})
      this.state.editableEvent.setMainImage(imageURL)
    } else {
      this.setState({uploadedImageURL: IMAGE_UPLOAD_STATUS.ERROR})
    }
  }

  onChange = (event: any) => {
    switch (event.target.id) {
      case "eventName":
        this.state.editableEvent.setTitle(event.target.value)
        break
      case "eventDesc":
        this.state.editableEvent.setDescription(event.target.value)
        break
      case "fbEventUrl":
        this.state.editableEvent.setProperties({
          ...this.state.editableEvent.properties.toJSON(),
          "facebookEventUrl": event.target.value,
        })
        break
      case "eventWebsiteUrl":
        this.state.editableEvent.setProperties({
          ...this.state.editableEvent.properties.toJSON(),
          "url": event.target.value,
        })
        break
      case "entrancePrice":
        this.updatePricingField(EVENT_PRICING_ITEM_ENTRANCE, EVENT_ITEM_PRICING_AMOUNT, event.target.value, true, 9999.99)
        break
      case "entranceVat":
        this.updatePricingField(EVENT_PRICING_ITEM_ENTRANCE, EVENT_ITEM_PRICING_VAT_PERCENTAGE, event.target.value, false, 100)
        break
      case "cloakroomPrice":
        this.updatePricingField(EVENT_PRICING_ITEM_CLOAKROOM, EVENT_ITEM_PRICING_AMOUNT, event.target.value, true, 9999.99)
        break
      case "cloakroomVat":
        this.updatePricingField(EVENT_PRICING_ITEM_CLOAKROOM, EVENT_ITEM_PRICING_VAT_PERCENTAGE, event.target.value, false, 100)
        break
      case "ticketQuota":
        if (event.target.value === "" || validator.isInt(event.target.value, {min: 1, max: 99999})) {
          this.state.editableEvent.setTicketQuota(parseInt(event.target.value))
        }
        break
      case "uploadPhoto":
        this.handleUploadImage(event.target.value)
        break
      default:
        break
    }
  }
  


  render() {
    const {buttonPressed, editableEvent, venue, uploadedImageURL} = this.state
    const {userSessionStore, t, match: {params}} = this.props
    let hideModalRef: any
    let revertModalRef: any
    let saveModalRef: any
    let backModalRef: any

    const EventImage = () => {
      const imageDimensions = {width: 300, height: 180, border: `1px solid ${colors.stroke}`}
      if (uploadedImageURL === "") {
        return <Card style={imageDimensions}/>
      } else if (uploadedImageURL === IMAGE_UPLOAD_STATUS.UPLOADING) {
        return <Skeleton height={imageDimensions.height} width={imageDimensions.width}/>
      } else if (uploadedImageURL === IMAGE_UPLOAD_STATUS.ERROR) {
        return <Card style={{width: imageDimensions.width, height: imageDimensions.height}}>
          <div className="alert alert-danger mt-5" role="alert">
            {t('manageVenuesScreen.imageUploadFailed')}
          </div>
        </Card>
      } else {
        return <img src={uploadedImageURL} style={{width: imageDimensions.width, height: imageDimensions.height}} alt="event"/>
      }
    }

    const urlIncludesHTTP = (str: string) => str.includes('http://') || str.includes('https://')

    const eventActionText = () => {
      if (params.createBasedOn === 'true') {
        return t("eventModifyScreen.add")
      } else if (params.eventId && params.createBasedOn === 'false') {
        return t("eventModifyScreen.modify")
      } else {
        return t("eventModifyScreen.add")
      }
    }

    return (
      <div>
        <Prompt
          when={this.state.showNavigationConfirmation && this.state.edited}
          message={t("navigationConfirmDialogMessage")}
        />
        <ModalBox getRef={(ref: any) => hideModalRef = ref.current}
                  title={t("eventModifyScreen.modals.hideConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("eventModifyScreen.modals.hideConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("eventModifyScreen.modals.hideConfirm.cancelButton")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="primary" onClick={action}>
                            {t("eventModifyScreen.modals.hideConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>
        <ModalBox getRef={(ref: any) => revertModalRef = ref.current}
                  title={t("eventModifyScreen.modals.revertConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("eventModifyScreen.modals.revertConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("eventModifyScreen.modals.revertConfirm.cancelButton")}
                          </Button>
                          <Button variant="primary" onClick={action}>
                            {t("eventModifyScreen.modals.revertConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => saveModalRef = ref.current}
                  title={t("eventModifyScreen.modals.saveConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("eventModifyScreen.modals.saveConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("eventModifyScreen.modals.saveConfirm.cancelButton")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="hentovihrea" onClick={action}>
                            {t("eventModifyScreen.modals.saveConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => backModalRef = ref.current}
                  title={t("eventModifyScreen.modals.backConfirm.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("eventModifyScreen.modals.backConfirm.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("eventModifyScreen.modals.backConfirm.cancelButton")}
                          </Button>
                          {/*
                          // @ts-ignore */}
                          <Button variant="primary" onClick={action}>
                            {t("eventModifyScreen.modals.backConfirm.okButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>
        <Container>
          <Row>
            <Col className="col-4 p-0">
              <Button
                onClick={
                  () => {
                    if (this.state.edited) {
                      backModalRef.show(
                        () => {
                          this.setState({
                            showNavigationConfirmation: false,
                          }, () => this.props.history.push(EventsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields())))
                        }
                      )
                    } else {
                      this.props.history.push(EventsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
                    }
                  }
                }
                variant="secondary"><FontAwesomeIcon className="mr-2"
                                                           icon={["fal", "chevrons-left"]}/>{t("eventModifyScreen.backButton")}
              </Button>
            </Col>
            <Col className="col-8 p-0 text-right">
              <Button
                disabled={!this.state.edited}
                className="mr-2" onClick={
                () => {
                  revertModalRef.show(async () => {
                    await this.resetEditing()
                    let formRef = this.formRef.current;
                    formRef.resetValidationState();
                    this.registerModificationAutorun(this.state.originalEvent, this.state.editableEvent)
                  })
                }
              }
                variant="secondary"><FontAwesomeIcon className="mr-2"
                                                             icon={["fal", "trash-undo"]}/>{t("eventModifyScreen.revertButton")}
              </Button>

              <Button
                disabled={!this.state.edited}
                className="" onClick={() => {
                saveModalRef.show(() => {
                  this.setState({
                    showNavigationConfirmation: false
                  }, () => this.saveModifications())
                })
              }}
                // @ts-ignore
                variant="hentovihrea"><FontAwesomeIcon className="mr-2"
                                                       icon={["fal", "cloud-arrow-up"]}/>{t("eventModifyScreen.saveButton")}
              </Button>
            </Col>
          </Row>
        </Container>

        <div className="text-center mt-5">
          <h2 className="p-0 m-0 text-primary font-weight-bold">{venue!.name}</h2>
          <p className="p-0 m-0 font-size-medium">{t("eventModifyScreen.slogan")}</p>
        </div>
        <ValidationForm ref={this.formRef}
                        onSubmit={(e: any) => {
                          e.preventDefault()
                          saveModalRef.show(() => {
                            this.setState({
                              showNavigationConfirmation: false
                            }, () => this.saveModifications())
                          })
                        }}
        >
          <fieldset disabled={buttonPressed}>
            <Card className="mt-5">
              <Card.Body>
                <Card.Title
                  className="">{eventActionText() + " " + this.props.t("eventModifyScreen.event")}</Card.Title>

                <div className="form-group row">
                  <div className="col-md-12 text-left p-0">
                    <div className="col-md-12 text-left">
                      <label htmlFor="eventName">{t("eventModifyScreen.eventName")}</label>
                      <TextInput style={{width: "60%"}} name="eventName" id="eventName" required
                                 errorMessage={{
                                   validator: t("validationErrorEventName"),
                                   required: t("fieldRequired"),
                                   maxLength: t("eventTitleMaxLength"),
                                 }}
                                 maxLength={64}
                                 successMessage={t("validationSuccess")}
                                 onChange={this.onChange}
                                 value={editableEvent.title}/>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12 text-left p-0">
                    <div className="col-md-12 text-left">
                      <label htmlFor="eventDesc">{t("eventModifyScreen.eventDesc")}</label>
                      <TextInput style={{width: "60%"}} name="eventDesc" id="eventDesc" required
                                 errorMessage={{
                                   validator: t("validationErrorEventDesc"),
                                   required: t("fieldRequired"),
                                 }}
                                 successMessage={t("validationSuccess")}
                                 onChange={this.onChange}
                                 multiline={true}
                                 rows={5}
                                 value={editableEvent.description}/>
                    </div>
                  </div>
                </div>
                
                <Container>
                  <ImageUploadModal
                    show={this.state.showUploadPhotoModal}
                    imageURL={this.state.imageURL}
                    aspectRatio={5 / 3}
                    onCroppedImage={(croppedImage) => {
                      this.setState({croppedImage: croppedImage})
                    }}
                    onCancel={() => {
                      this.setState({showUploadPhotoModal: false})
                    }}
                    onSave={() => {
                      this.setState({showUploadPhotoModal: false})
                      this.setState({selectedImage: this.state.croppedImage})
                      this.onChange({target: {id: 'uploadPhoto', value: this.state.croppedImage}})
                    }}/>

                  <Row>
                    <Col md={6} className="p-0">
                      <h6 className={`mt-3 mb-0`}>{t(`eventScreen.eventPhoto`)}</h6>
                      <div className="mb-2">
                        <small>{t('productModifyScreen.productPhotoDescription')}</small>
                      </div>

                      <label className="btn btn-secondary">
                          {t(`manageVenuesScreen.uploadPhoto`)}
                          <input
                            name="file" 
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={(event) => {
                              if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]?.type.includes("image")) {
                                this.setState({
                                  imageURL: URL.createObjectURL(event.currentTarget.files[0]),
                                  showUploadPhotoModal: true
                                })
                              }
                          }} />
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4} className="p-0 mb-4">
                      <EventImage/>
                    </Col>
                  </Row>

                </Container>
  
                <Card.Title className="mb-0">{t("eventModifyScreen.schedule")}</Card.Title>
                <p>
                  <small>({t("eventModifyScreen.timeInRestaurant")})</small>
                </p>

                <Container>
                  <Row>
                    <Col className="flex-column col-2 p-0">
                      <div>
                        {t("eventModifyScreen.startDate")}
                      </div>
                      <DatePicker selected={new Date(moment(this.state.startDate))}
                                  onChange={this.handleStartDateSelect}
                                  dateFormat="P"
                                  locale={i18n.languages[0]}
                                  customInput={<CustomDatePicker/>}/>
                    </Col>
                    <Col className="flex-column">
                      <div>
                        {t("eventModifyScreen.startTime")}
                      </div>
                      <DatePicker
                        selected={new Date((this.state.startDate))}
                        onChange={this.handleStartDateSelect}
                        showTimeSelect
                        showTimeSelectOnly
                        locale={i18n.languages[0]}
                        timeIntervals={15}
                        timeCaption={t("time")}
                        dateFormat="p"
                        customInput={<CustomTimePicker/>}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="flex-column col-2 p-0">
                      <div className="mt-2">
                        {t("eventModifyScreen.endDate")}
                      </div>
                      <DatePicker selected={new Date(this.state.endDate)}
                                  onChange={this.handleEndDateSelect}
                                  dateFormat="P"
                                  locale={i18n.languages[0]}
                                  customInput={<CustomDatePicker/>}/>
                    </Col>
                    <Col className="flex-column">
                      <div className="mt-2">
                        {t("eventModifyScreen.endTime")}
                      </div>
                      <DatePicker
                        selected={new Date(this.state.endDate)}
                        onChange={this.handleEndDateSelect}
                        showTimeSelect
                        showTimeSelectOnly
                        locale={i18n.languages[0]}
                        timeIntervals={15}
                        timeCaption={t("time")}
                        dateFormat="p"
                        customInput={<CustomTimePicker/>}
                      />
                    </Col>
                  </Row>
                </Container>
                <Card.Title className="mt-2">{t("eventModifyScreen.links")}</Card.Title>
                <div className="form-group row">
                  <div className="col-md-12 text-left p-0">
                    <div className="col-md-12 text-left">
                      <label htmlFor="fbEventUrl">{t("eventModifyScreen.facebookEventUrl")}</label>
                      <TextInput style={{width: "60%"}} name="fbEventUrl" id="fbEventUrl"
                                 validator={(url: string) => validator.isEmpty(url) || urlIncludesHTTP(url) && validator.isURL(url)}
                                 errorMessage={{validator: t("validationErrorEventUrl")}}
                                 successMessage={t("validationSuccess")}
                                 onChange={this.onChange}
                                 value={this.state.editableEvent.properties.get("facebookEventUrl") || ""}/>
                    </div>
                  </div>
                  <div className="col-md-12 text-left p-0">
                    <div className="col-md-12 text-left">
                      <label htmlFor="eventWebsiteUrl">{t("eventModifyScreen.eventWebsiteUrl")}</label>
                      <TextInput style={{width: "60%"}} name="eventWebsiteUrl" id="eventWebsiteUrl"
                                 validator={(url: string) => validator.isEmpty(url) || urlIncludesHTTP(url) && validator.isURL(url)}
                                 errorMessage={{validator: t("validationErrorEventUrl")}}
                                 successMessage={t("validationSuccess")}
                                 onChange={this.onChange}
                                 value={this.state.editableEvent.properties.get("url") || ""}/>
                    </div>
                  </div>
                </div>
                <Card.Title className="">{t("eventModifyScreen.type")}</Card.Title>
                <Container>
                  <Row>
                    {this.state.status === EVENT_STATUS_HIDDEN &&
                    <Col className="col-4">
                        <Dropdown
                          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <Dropdown.Toggle disabled={true} variant="outline-primary" id="dropdown-event-type">
                              {t("eventModifyScreen.hidden")}
                            </Dropdown.Toggle>
                        </Dropdown>
                    </Col>
                    }
                    {this.state.status !== EVENT_STATUS_HIDDEN && <Col className="col-4">
                        <Dropdown
                          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
                            onClick={(e: any) => e.stopPropagation()}
                            onSelect={this.handleTypeSelect}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-event-type">
                              {t("eventModifyScreen." + this.state.type)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={EVENT_TYPE_PAID}
                                               active={this.state.type === EVENT_TYPE_PAID}>
                                  {t("eventModifyScreen.paidEvent")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={EVENT_TYPE_FREE}
                                               active={this.state.type === EVENT_TYPE_FREE}>
                                  {t("eventModifyScreen.freeEvent")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>}
                    {this.state.status !== EVENT_STATUS_HIDDEN && this.state.type === EVENT_TYPE_PAID &&
                    <Col className="col-4">
                        <Dropdown
                          /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
                            onClick={(e: any) => e.stopPropagation()}
                            onSelect={this.handleStatusSelect}>
                            <Dropdown.Toggle disabled={this.state.status === "hidden"} variant="secondary"
                                             id="dropdown-event-status">
                              {t("eventModifyScreen." + this.state.status)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={EVENT_STATUS_PAID_NORMAL}
                                               active={this.state.status === EVENT_STATUS_PAID_NORMAL}>
                                  {t("eventModifyScreen.normal")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={EVENT_STATUS_PAID_NOT_SOLD}
                                               active={this.state.status === EVENT_STATUS_PAID_NOT_SOLD}>
                                  {t("eventModifyScreen.notSold")}
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={EVENT_STATUS_PAID_SOLDOUT}
                                               active={this.state.status === EVENT_STATUS_PAID_SOLDOUT}>
                                  {t("eventModifyScreen.atDoor")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    }
                    <Col className="col-4 d-flex justify-content-center align-items-center">
                      <Check
                        onChange={(event: any) => {
                          let checked = event.target.checked
                          if (this.state.editableEvent.isHidden()) {
                            this.handleStatusSelect(EVENT_STATUS_HIDDEN, checked)
                          } else {
                            hideModalRef.show(() => {
                              this.handleStatusSelect(EVENT_STATUS_HIDDEN, checked)
                            })
                          }
                        }}
                        type="switch"
                        id="hidden"
                        checked={this.state.status === EVENT_STATUS_HIDDEN}
                        label={t("eventModifyScreen.hideEventFromUsers")}
                      />
                    </Col>
                  </Row>
                  {(this.state.status === EVENT_STATUS_PAID_NORMAL || this.state.status === EVENT_STATUS_PAID_SOLDOUT) && this.state.type === EVENT_TYPE_PAID ?
                    <Row>
                      <Col className="p-0">
                        <Container>
                          <Row>
                            <Col className="flex-column col-2 mr-4">
                              <div className="mt-2">{t("eventModifyScreen.entrancePrice")}</div>
                              <TextInput style={{width: "70%"}} name="entrancePrice" id="entrancePrice" required
                                         disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                //validator={eventPricingNumberValidator()}
                                         errorMessage={{
                                           pattern: t("validationErrorPrice"),
                                           required: t("fieldRequired"),
                                         }}
                                         successMessage={t("validationSuccess")}
                                         onChange={this.onChange}
                                         pattern="(.*)[0-9](.*)"
                                         value={this.state.pricing[EVENT_PRICING_ITEM_ENTRANCE].amount}
                              />
                            </Col>
                            <Col className="flex-column col-2">
                              <div className="mt-2">{t("eventModifyScreen.entranceVat")}</div>
                              <TextInput style={{width: "65%"}} name="entranceVat" id="entranceVat" required
                                         disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                //validator={eventPricingNumberValidator(100)}
                                         errorMessage={{
                                           pattern: t("validationErrorVat"),
                                           required: t("fieldRequired"),
                                         }}
                                         successMessage={t("validationSuccess")}
                                         onChange={this.onChange}
                                         pattern="(.*)[0-9](.*)"
                                         value={this.state.pricing[EVENT_PRICING_ITEM_ENTRANCE].vat_percentage}
                              />
                            </Col>
                            <Col className="flex-column col-3 d-flex">
                              <div className="mt-2">&nbsp;</div>
                              <div className="mt-2  d-flex align-items-center justify-content-end mr-3">
                                <Check
                                  disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                  id="enableTicketQuota"
                                  onChange={(event: any) => {
                                    const checked = event.target.checked
                                    this.setState({enableTicketQuota: checked})
                                    if (!checked) {
                                      this.state.editableEvent.setTicketQuota(undefined)
                                    } else {
                                      this.state.editableEvent.setTicketQuota(100)
                                    }
                                  }}
                                  type="switch"
                                  checked={this.state.enableTicketQuota}
                                  label={t("eventModifyScreen.limitQuantity")}
                                />
                              </div>
                            </Col>
                            {this.state.enableTicketQuota &&
                            <Col className="flex-column col-3">
                                <div className="mt-2">{t("eventModifyScreen.maxVisitorCount")}</div>
                                <TextInput name="ticketQuota" id="ticketQuota" required
                                           disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                           style={{width: "55%"}}
                                  //validator={(value: string) => validator.isInt(value, {min: 1, max: 1000000})}
                                           errorMessage={{
                                             pattern: t("validationErrorTicketQuota"),
                                             required: t("fieldRequired"),
                                           }}
                                           successMessage={t("validationSuccess")}
                                           pattern="(.*)[1-9](.*)"
                                           onChange={this.onChange}
                                           value={this.state.editableEvent.properties.has(EVENT_PROPERTIES_TICKET_QUOTA) ? this.state.editableEvent.properties.get(EVENT_PROPERTIES_TICKET_QUOTA) : ""}
                                />
                            </Col>
                            }
                          </Row>

                          <Row>
                            <Col className="flex-column col-2 mr-4">
                              <div className="mt-2">{t("eventModifyScreen.cloakroomPrice")}</div>
                              <TextInput style={{width: "70%"}} name="cloakroomPrice" id="cloakroomPrice" required
                                         disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                //validator={eventPricingNumberValidator()}
                                         errorMessage={{
                                           pattern: t("validationErrorPrice"),
                                           required: t("fieldRequired"),
                                         }}
                                         successMessage={t("validationSuccess")}
                                         onChange={this.onChange}
                                         pattern="(.*)[0-9](.*)"
                                         value={this.state.pricing[EVENT_PRICING_ITEM_CLOAKROOM].amount}
                              />
                            </Col>
                            <Col className="flex-column col-2">
                              <div className="mt-2">{t("eventModifyScreen.cloakroomVat")}</div>
                              <TextInput style={{width: "65%"}} name="cloakroomVat" id="cloakroomVat" required
                                         disabled={this.state.status === EVENT_STATUS_PAID_SOLDOUT}
                                //validator={eventPricingNumberValidator(100)}
                                         errorMessage={{
                                           pattern: t("validationErrorVat"),
                                           required: t("fieldRequired"),
                                         }}
                                         successMessage={t("validationSuccess")}
                                         onChange={this.onChange}
                                         pattern="(.*)[0-9](.*)"
                                         value={this.state.pricing[EVENT_PRICING_ITEM_CLOAKROOM].vat_percentage}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                    : null}
                </Container>

                <AlertMessage source={EVENT_MODIFY_SCREEN_ERROR}/>
                <Button
                  type="submit"
                  disabled={!this.state.edited}
                  className="mt-5 mr-2"
                  // @ts-ignore
                  variant="hentovihrea"><FontAwesomeIcon className="mr-2"
                                                         icon={["fal", "cloud-arrow-up"]}/>{t("eventModifyScreen.saveButton")}
                </Button>
              </Card.Body>
            </Card>

          </fieldset>
        </ValidationForm>
      </div>
    )
  }
}

export default withTranslation()(EventsModifyScreen)
