import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import { inject, observer } from "mobx-react"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../../Api"
import { RewardModifyLocation, StampCardModifyLocation } from "../../Locations"
import AlertMessage from "../../components/AlertMessage"
import { SummaryHeader } from "../../components/SummaryHeader"
import UpgradeToPremiumModal from "../../components/UpgradeToPremiumModal"
import { IBenefitReportStore } from "../../models/BenefitReportStore"
import { ILoyaltyCard } from "../../models/LoyaltyCard"
import { ILoyaltyCardStore } from "../../models/LoyaltyCardStore"
import { IMessageStore } from "../../models/MessageStore"
import { REWARD_TYPE } from "../../models/RewardStore"
import { IRootStore } from "../../models/RootStore"
import { IUserSessionStore } from "../../models/UserSessionStore"
import { IVenue } from "../../models/Venue"
import RewardsList from "./RewardsList"
import StampCardsList from "./StampCardsList"

const BENEFIT_SCREEN_ERROR = "benefitScreen"

export interface RewardsScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  benefitReportStore?: IBenefitReportStore
  loyaltyCardStore?: ILoyaltyCardStore

  rootStore?: IRootStore
  api?: MerchantAdminApi
  messageStore?: IMessageStore
  memberStore?: any
}



export const calculateRewardScreenSummaries = (loyaltyCards: ILoyaltyCard[] = [], t: TFunction) => {
  let totalMembersWithStamps = 0;
  let newMembersLast30Days = 0;
  let totalRewardsRedeemed = 0;
  let rewardsRedeemedLast30Days = 0;
  let totalRewardsCollected = 0;
  let rewardsCollectedLast30Days = 0;
  let totalStampsCollected = 0;
  let stampsCollectedLast30Days = 0;

  loyaltyCards.forEach(card => {
    totalMembersWithStamps += card.total_unique_users || 0;
    newMembersLast30Days += card.total_unique_users_30 || 0;
    totalRewardsRedeemed += card.rewards_redeemed || 0;
    rewardsRedeemedLast30Days += card.rewards_redeemed_30 || 0;
    totalRewardsCollected += card.total_rewards || 0;
    rewardsCollectedLast30Days += card.total_rewards_30 || 0;
    totalStampsCollected += card.total_stamps || 0;
    stampsCollectedLast30Days += card.total_stamps_30 || 0;
  });


  return [
    ...(Boolean(totalMembersWithStamps) ? [ {
      title: t("rewardsScreen.membersWithStamps"),
      value: String(totalMembersWithStamps),
      footer: `${String(newMembersLast30Days)} ${t("rewardsScreen.newMembersLast30Days")}`,
      footerSub: t("benefitScreen.summary.activationsLastMonthFooter"),
    }]: []),

    ...(Boolean(totalStampsCollected) ? [     {
      title: t("rewardsScreen.stampsCollected"),
      value: String(totalStampsCollected),
      footer: `${String(stampsCollectedLast30Days)} ${t("rewardsScreen.newStampsLast30Days")}`,
      footerSub: t("benefitScreen.summary.activationsLastMonthFooter"),
    },]: []),

    {
      title: t("rewardsScreen.rewardsCollected"),
      value: String(totalRewardsCollected),
      footer: `${String(rewardsCollectedLast30Days)} ${t("rewardsScreen.collectedLast30Days")}`,
      footerSub: t("benefitScreen.summary.activationsLastMonthFooter"),
    },

    {
      title: t("rewardsScreen.rewardsRedeemed"),
      value: String(totalRewardsRedeemed),
      footer: `${String(rewardsRedeemedLast30Days)} ${t("rewardsScreen.redeemedLast30Days")}`,
      footerSub: t("benefitScreen.summary.activationsLastMonthFooter"),
    }
  ];
};




export const RewardsScreen = (props: Readonly<RewardsScreenProps>) => {
    const {userSessionStore, loyaltyCardStore, history, t} = props    

    const [showSendModal, setShowSendModal] = useState(false);
    
    const navToStampCardModifyScreen = (venue: IVenue) =>
    history.push(StampCardModifyLocation.toUrl({...venue.urlFields(), stampCardId: "NEW"}))
    const navToRewardModifyScreen = (venue: IVenue, rewardType: string) =>
    history.push(RewardModifyLocation.toUrl({...venue.urlFields(), rewardId: "NEW", rewardType: rewardType}))


    const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium



    const NewStampCardButton = () => {
      return <>
      {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            className="mr-2"
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "plus-circle"]}/>{t("rewardsScreen.newStampCard")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                onClick={() => navToStampCardModifyScreen(venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
          className="mr-2"
          onClick={() => navToStampCardModifyScreen(userSessionStore!.selectedBranch! as IVenue)}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "plus-circle"]}/>{t("rewardsScreen.newStampCard")}
          </Button>}
      </>
    }

    const NewRewardButton = () => {


     if (!userSessionStore?.isSuperAdmin) {
      return null
     }

     return <Dropdown
            className="mr-2"
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "plus-circle"]}/>{t("rewardsScreen.newReward")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item 
             onClick={() => {
              isOrganizationPremium ?
              navToRewardModifyScreen(userSessionStore!.selectedBranch! as IVenue, REWARD_TYPE.REWARD_JOIN) : setShowSendModal(true)
            }}
                  >{t("rewardsScreen.signUpReward")}</Dropdown.Item>
                    <Dropdown.Item 
             onClick={() => {
              isOrganizationPremium ?navToRewardModifyScreen(userSessionStore!.selectedBranch! as IVenue, REWARD_TYPE.REWARD_BIRTHDAY) : setShowSendModal(true)
            }
            }
                  >{t("rewardsScreen.birthdayGift")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

    }


    const NewLoyaltyPointsButton = () => {
      return <>
       {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-benefits-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "plus-circle"]}/>{t("rewardsScreen.newPointSystem")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                onClick={() => console.log('')}
                                //  onClick={this.forwardToModifyScreen.bind(this, venue)}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
          onClick={() => console.log('')}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "plus-circle"]}/>{t("rewardsScreen.newPointSystem")}
          </Button>}
      </>
    }


    const headerButtons = (
      <>
      {/* <NewRewardButton/> */}
      {/* <NewStampCardButton/>
       <NewLoyaltyPointsButton/> */}
      </>
    )

    const loyaltyCards = userSessionStore!.isOrganizationContext() ? 
    loyaltyCardStore?.loyaltyCards : 
    loyaltyCardStore?.loyaltyCards?.filter(card => card.restaurantId.id === userSessionStore?.selectedBranch?.id)

    return (
      <div>
        <AlertMessage source={BENEFIT_SCREEN_ERROR}/>
        <SummaryHeader 
            summaries={calculateRewardScreenSummaries(loyaltyCards, t)} 
            // isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} 
            buttons={headerButtons}
            // buttons={[]}
            />

        <StampCardsList stamps={loyaltyCards} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} t={t}/>
        <RewardsList isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()}  />
        <UpgradeToPremiumModal
          showModal={showSendModal}
          setShowModal={(value: boolean) => setShowSendModal(value)}/>

      </div>
    )
}

// export default withTranslation()(MessagesScreen)
export default withRouter(withTranslation()(inject("userSessionStore", "loyaltyCardStore", "api")(observer(RewardsScreen))))