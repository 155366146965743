const citiesList = [
    'Äänekoski',
    'Helsinki',
    'Turku',
    // 'Kotka',
    'Järvenpää',
    'Porvoo',
    'Tampere',
    'Vantaa',
    'Espoo',
    'Marbella',
    'Muurame',
    'Seinäjoki',
    'Oulu',
    'Pieksämäki',
    'Hämeenlinna',
    'Kalajoki',
    'Joensuu',
    'Jyväskylä',
    'Kuopio',
    'Kauniainen',
    'Lahti',
    'Laukaa',
    'Lappeenranta',
    'Lohja',
    'Mäntsälä',
    'Mikkeli',
    'Hyvinkää',
    'Vaasa',
    'Sipoo',
    'Sastamala',
    'Savonlinna',
    'Hanko'
]

const normalize = (name: string) => name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ä/gi, "a").replace(/ö/gi, "o").toLowerCase();

const sortedCitiesInAlphabeticalOrder = citiesList.sort((a, b) => normalize(a).localeCompare(normalize(b)));

export const cities = sortedCitiesInAlphabeticalOrder

