import React, { ReactNode, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import TextTruncate from 'react-text-truncate';
import { colors } from "../theme/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SummaryItem {
  venue?: string;
  title: string;
  value: string;
  footer: string;
  footerSub?: string;
}

export interface SummaryHeaderProps {
  summaries: Array<SummaryItem>;
  buttons: ReactNode;
  isSingleVenue?: boolean;
}

export const SummaryHeader = ({ summaries, buttons, isSingleVenue }: SummaryHeaderProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(true);

  interface ScrollArrowProps {
    direction: 'left' | 'right';
    onClick: () => void;
  }

  const summaryHeaderHeight = isSingleVenue ? 142 : 162
  
  const ScrollArrow: React.FC<ScrollArrowProps> = ({ direction, onClick }) => {
    const arrowStyle: React.CSSProperties = {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      [direction === 'left' ? 'left' : 'right']: '0px',
      zIndex: 2,
      height: summaryHeaderHeight,
      background: direction === 'left'
      ? 'linear-gradient(to right, rgba(32, 32, 32, 1), rgba(32, 32, 32, 0.8), rgba(32, 32, 32, 0.6), transparent)' 
      : 'linear-gradient(to left, rgba(32, 32, 32, 1), rgba(32, 32, 32, 0.8), rgba(32, 32, 32, 0.6), transparent)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 70,
      // transition: 'opacity 0.1s ease'
    };
  
    return (
      <div onClick={onClick} className="scrollArrowCluby" style={arrowStyle}>
         <FontAwesomeIcon  style={{
          fontSize: 32,
          color: colors.white
        }} icon={["fal", direction === 'left' ?  "chevron-left" : 'chevron-right']}/>
      </div>
    );
  };
  

  const scrollList = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const scrollAmount = direction === 'left' ? -listRef.current.offsetWidth : listRef.current.offsetWidth;
      listRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const checkScrollPosition = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    checkScrollPosition(); // Call on mount to set initial arrow visibility
    const element = listRef.current;
    if (element) {
      element.addEventListener('scroll', checkScrollPosition);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, []);
  return (
    <Container className="mb-4" style={{ position: 'relative' }}>
       {showLeftArrow && <ScrollArrow direction="left" onClick={() => scrollList('left')} />}
      <Row className="d-flex flex-row justify-content-end">
        <div className="flex-grow-1 overflow-auto hide-scrollbar" ref={listRef}
        //  style={{ paddingBottom: 4 }}
         >
          <ListGroup className="text-center hide-scrollbar" horizontal={true}>
          {summaries.map((summary, index) => (
              <div className="summary-card-wrapper" style={{
                height: summaryHeaderHeight
              }} key={index}>
                <ListGroup.Item className="mr-2 rounded summary-box" style={{ backgroundColor: colors.boxFill }}>
                  <div className="summary-box-title font-heading mt-1 text-primary" style={{ fontSize: '1rem', alignItems: 'flex-start' }}>
                    <TextTruncate line={2} element="span" truncateText="…" text={summary.title} />
                  </div>
                  {summary.venue && (
                    <div className="font-heading font-size-small summary-box-venue" style={{ color: colors.primaryText, }}>
                      {summary.venue}
                    </div>
                  )}
                  <div className="font-size-large font-heading text-primary summary-box-value">{summary.value}</div>
                  <div className="summary-box-footer text-secondary">{summary.footer}
                  
                  {summary.footerSub &&
                    <div style={{
                      textTransform: 'lowercase'
                    }} className="text-secondary">{summary.footerSub}
                    </div>
                  }
       
                  </div>
                </ListGroup.Item>
              </div>
            ))}
          </ListGroup>
        </div>
      </Row>
      {showRightArrow && <ScrollArrow direction="right" onClick={() => scrollList('right')} />}
      <div className="p-0 pt-4 px-0 text-right d-flex align-items-end flex-row justify-content-end">
        {buttons}
      </div>
    </Container>
  );
};
