import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import log from "loglevelnext"
import { inject, observer } from "mobx-react"
import React from "react"
import Button from "react-bootstrap/Button"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"
import { SelectGroup, TextInput, ValidationForm } from "react-bootstrap4-form-validation"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MerchantAdminApi } from "../Api"
import { MembersStatusSubscriptionsModifyLocation, MembersStatusesModifyLocation } from "../Locations"
import AlertMessage from "../components/AlertMessage"
import MemberTable from "../components/MemberTable"
import { ModalBox } from "../components/ModalBox"
import { SummaryHeader, SummaryItem } from "../components/SummaryHeader"
import UpgradeToPremiumModal from "../components/UpgradeToPremiumModal"
import { IBenefitReportStore } from "../models/BenefitReportStore"
import { IEventStore } from "../models/EventStore"
import { ILoyaltyCardStore } from "../models/LoyaltyCardStore"
import { IMemberStore } from "../models/MemberStore"
import { IProductReportStore } from "../models/ProductReportStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { IVenue } from "../models/Venue"
import { trackEvent } from "../tracking"
import trackingConstants from "../tracking/trackingConstants"
import { cities } from "../utils/constants"
import { STATUS_TYPE } from "./ManageMemberStatusesScreen"
const MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR = "memberScreenPushNotificationModal"

export interface MembersScreenProps extends RouteComponentProps, WithTranslation {
  userSessionStore?: IUserSessionStore
  memberStore?: IMemberStore
  eventStore?: IEventStore
  api?: MerchantAdminApi
  benefitReportStore?: IBenefitReportStore
  productReportStore?: IProductReportStore
  loyaltyCardStore?: ILoyaltyCardStore

}

@(withRouter as any)
@inject("userSessionStore")
@inject("memberStore")
@inject("eventStore")
@inject("benefitReportStore")
@inject("productReportStore")
@inject("loyaltyCardStore")
@inject("api")
@observer
class MembersScreen extends React.Component<MembersScreenProps, {isPremiumModalShown: boolean}> {
  constructor(props: any) {
    super(props)
    this.state = {
      isPremiumModalShown: false
    }
  }

  componentDidMount(): void {
    log.debug("MembersScreen mounted")
    trackEvent(trackingConstants.screens.members, {selectedBranch: this.props.userSessionStore!.selectedBranch!.name})
  }

  async onSubmitPushNotification(modalAction: any, e: any, formData: any, inputs: any) {
    e.preventDefault()
    const {api} = this.props

    try {
      
      const requestSuccess = await api!.sendSimplePushNotificationRequest(
        `Target audience: ${formData.targetAudience}\n${formData.notificationText}`,
        undefined,
        MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR,
      )

      if (requestSuccess) {
        modalAction()
        return
      }

    } finally {

    }

    log.debug("MemberScreen: Error while sending push notification")
  }

  render() {
    const {api, userSessionStore, memberStore, benefitReportStore, eventStore, loyaltyCardStore, productReportStore, history, t} = this.props
    const currentMonthJoinedCount = memberStore!.reporting.newMembersLast30Days


    const summaries: Array<SummaryItem> = [
      {
        title: t("memberScreen.summary.totalMembersTitle"),
        value: memberStore?.stats?.membersCount?.toString() || '0',
        footer: t("memberScreen.summary.totalMembersFooter"),
      },
      {
        title: t("memberScreen.summary.newMembersTitle"),
        value:  memberStore?.stats?.membersLastMonth?.toString() || '0',
        footer: t("memberScreen.summary.newMembersFooter"),
      },
    ]

    const venueStatusesStats = memberStore!.stats?.venueStatuses || []


    venueStatusesStats.forEach((venueStatusStat: any) => {
    
      summaries.push({
        venue: userSessionStore!.currentOrganization!.isSingleVenue() ? undefined : venueStatusStat.id?.restaurantId?.name,
        title: venueStatusStat.id.localeTitle,
        value: venueStatusStat.membersCount,
        footer: t("memberScreen.summary.statusMembersFooter"),
      })
    })

   
    let sendPushNotificationModalRef: any
    let sendPushNotificationAckModalRef: any
    let statusManageInfoModalRef: any
    let sendMessageInfoModalRef: any

    const isOrganizationPremium = userSessionStore?.currentOrganization?.isPremium

    const actionButtons = (
      <>
      
      {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
            className="mr-2"
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "arrows-rotate"]}/>{t("memberScreen.sellSubscriptions")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={() => history.push(MembersStatusSubscriptionsModifyLocation.toUrl({
                                   ...venue.urlFields(),
                                   statusType: STATUS_TYPE.PUBLIC
                                 }))}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            className="mr-2"
            onClick={() => history.push(MembersStatusSubscriptionsModifyLocation.toUrl({
              ...userSessionStore!.selectedBranch!.urlFields(),
              statusType: STATUS_TYPE.PUBLIC
            } as IVenue))}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "arrows-rotate"]}/>{t("memberScreen.sellSubscriptions")}
          </Button>
        }
        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            onClick={(e: any) => e.stopPropagation()}
            className="mr-2"
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
              <FontAwesomeIcon className="mr-2" icon={["fal", "credit-card-blank"]}/>{t("memberScreen.sellCards")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                                 onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
                                   ...venue.urlFields(),
                                   statusType: STATUS_TYPE.PUBLIC
                                 }))}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            className="mr-2"
            onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
              ...userSessionStore!.selectedBranch!.urlFields(),
              statusType: STATUS_TYPE.PUBLIC
            } as IVenue))}
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "credit-card-blank"]}/>{t("memberScreen.sellCards")}
          </Button>
        }

        {userSessionStore!.isOrganizationContext() ?
          <Dropdown
            /* @FIXME: https://github.com/react-bootstrap/react-bootstrap/issues/5409 */
            // onClick={(e: any) => e.stopPropagation()}
            onClick={(e: any) => isOrganizationPremium ? e.stopPropagation() : this.setState({isPremiumModalShown: true})}
          >
            <Dropdown.Toggle variant="success" id="dropdown-edit-products-org">
              <FontAwesomeIcon  icon={["fal", "star"]} style={{marginRight: 5}}/>{t("memberScreen.giveCards")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => {
                return (
                  <Dropdown.Item key={venue.id}
                    onClick={() => history.push(MembersStatusesModifyLocation.toUrl({
                      ...venue.urlFields(),
                      statusType: STATUS_TYPE.PRIVATE
                    }))}
                  >{venue.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          :
          <Button
            onClick={() => isOrganizationPremium ? history.push(MembersStatusesModifyLocation.toUrl({
                            ...userSessionStore!.selectedBranch!.urlFields(),
                            statusType: STATUS_TYPE.PRIVATE
                          } as IVenue))
                          :
                          this.setState({isPremiumModalShown: true})
                    }
            variant="success"><FontAwesomeIcon className="mr-2"
                                               icon={["fal", "star"]}/>{t("memberScreen.giveCards")}
          </Button>
        }
      </>
    )

    const venueNames = userSessionStore!.currentOrganization!.venues!.map((venue: IVenue) => venue.name)
    const defaultTargetAudience = userSessionStore!.isOrganizationContext() ? 'all' : this.props.userSessionStore!.selectedBranch!.name


    const membersData = memberStore!.members.slice()

    const hasAnyBenefitActivations = benefitReportStore!.reporting.benefitsUsedTotal > 0
  
    const hasAnyEventsSales = eventStore?.events?.length
    const hasAnyProductsSales = productReportStore?.reporting.soldTotal
    const hasAnySales = (hasAnyEventsSales || hasAnyProductsSales) ? true : false
    


    return (
      <div>
        <UpgradeToPremiumModal showModal={this.state.isPremiumModalShown} setShowModal={(value: boolean) => this.setState({isPremiumModalShown: value})}/>
        <ModalBox getRef={(ref: any) => statusManageInfoModalRef = ref.current}
                  title={t("memberScreen.modals.statusManageInfo.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("memberScreen.modals.statusManageInfo.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberScreen.modals.statusManageInfo.cancelButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => sendMessageInfoModalRef = ref.current}
                  title={t("memberScreen.modals.sendMessageInfo.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("memberScreen.modals.sendMessageInfo.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberScreen.modals.sendMessageInfo.cancelButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => sendPushNotificationAckModalRef = ref.current}
                  title={t("memberScreen.modals.sendPushNotificationAck.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <Modal.Body>{t("memberScreen.modals.sendPushNotificationAck.text")}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="primary" onClick={cancel}>
                            {t("memberScreen.modals.sendPushNotificationAck.cancelButton")}
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  }}/>

        <ModalBox getRef={(ref: any) => sendPushNotificationModalRef = ref.current}
                  title={t("memberScreen.modals.sendPushNotification.title")}
                  renderContent={(action, cancel) => {
                    return (
                      <>
                        <ValidationForm onSubmit={this.onSubmitPushNotification.bind(this, action)}>
                          <Modal.Body>
                            <p>{t("memberScreen.modals.sendPushNotification.text")}</p>
                            
                            <fieldset disabled={false}>
                              <div className="form-group row">
                                <div className="col-md-12 text-left">
                                  <label
                                    htmlFor="Message">{t("memberScreen.modals.sendPushNotification.messageLabel")}</label>
                                    <SelectGroup name="targetAudience" id="targetAudience"
                                        required errorMessage="Please select a target audience"
                                        defaultValue={defaultTargetAudience}
                                        >
                                        <option value="all">{t("allVenues")}</option>
                                        {cities.map(city => <option>{city}</option>)}
                                        {venueNames.map(venueName => <option key={venueName} value={venueName}>{venueName}</option>)}
                                    </SelectGroup>

                                  <AlertMessage source={MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR}/>

                                </div>
                              </div>
                            </fieldset>

                            <fieldset disabled={false}>
                              <div className="form-group row">
                                <div className="col-md-12 text-left">
                                  <label
                                    htmlFor="Message">{t("memberScreen.modals.sendPushNotification.messageLabel")}</label>
                                  <TextInput name="notificationText" id="notificationText" required
                                             rows="5"
                                             maxLength="255"
                                             multiline
                                    //value={this.state.formData.email}
                                    //onChange={this.handleChange}
                                    //validator={validator.isEmail}
                                             errorMessage={{
                                               validator: t("validationErrorEmail"),
                                               required: t("fieldRequired"),
                                             }}
                                             successMessage={t("validationSuccess")}/>

                                  <AlertMessage source={MEMBER_SCREEN_PUSH_NOTIFICATION_MODAL_ERROR}/>

                                </div>
                              </div>
                            </fieldset>

                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="primary" onClick={cancel}>
                              {t("memberScreen.modals.sendPushNotification.cancelButton")}
                            </Button>
                            <Button
                              type="submit"
                              // @ts-ignore
                              variant="hentovihrea">
                              {t("memberScreen.modals.sendPushNotification.okButton")}
                            </Button>
                          </Modal.Footer>
                        </ValidationForm>
                      </>
                    )
                  }}/>

        <SummaryHeader summaries={summaries} isSingleVenue={userSessionStore!.currentOrganization!.isSingleVenue()} buttons={actionButtons}/>
          <MemberTable data={membersData}
            showStatuses={memberStore!.statuses.length > 0}
            stats={{...memberStore!.stats}}
            filter={{...memberStore!.filter}}
            hasAnyBenefitActivations={hasAnyBenefitActivations}
            hasAnySales={hasAnySales}
          />
      </div>
    )
  }
}

export default withTranslation()(MembersScreen)
