import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field, Form, Formik } from 'formik'
import { inject, observer } from "mobx-react"
import { useState } from "react"
import { Container, InputGroup } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Card from 'react-bootstrap/Card'
import Col from "react-bootstrap/Col"
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Modal from 'react-bootstrap/Modal'
import Row from "react-bootstrap/Row"
import { WithTranslation, withTranslation } from "react-i18next"
import Skeleton from 'react-loading-skeleton'
import * as Yup from 'yup'
import { MerchantAdminApi } from "../Api"
import { ProductsLocation, RewardsLocation } from "../Locations"
import AlertMessage from "../components/AlertMessage"
import ImageUploadModal from "../components/ImageUploadModal"
import { IProductStore } from "../models/ProductStore"
import { IUserSessionStore } from "../models/UserSessionStore"
import { colors } from "../theme/colors"
import { fullCurrencyUnitsToCents, urlToFile } from "../utils"

const MODIFY_PRODUCT_ERROR = "modifyProductError"
const CREATE_UPDATE_PRODUCT_ERROR = "createUpdateProductError"
export interface CreateUpdateStampCardScreenProps extends WithTranslation {
  api?: MerchantAdminApi
  userSessionStore?: IUserSessionStore
  productStore?: IProductStore
  history?: any
}

const CreateUpdateStampCardScreen = (props: CreateUpdateStampCardScreenProps) => {
  const [croppedStampCardPhoto, setCroppedStampCardPhoto] = useState('')
  const [showUploadStampCardPhotoModal, setShowUploadStampCardPhotoModal] = useState(false);
  const [showGoBackModal, setShowGoBackModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);

  const getStampCardIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    return urlVars[urlVars.length - 1]
  }

  const selectedStampCardId = getStampCardIDFromURL()
  const selectedStampCard = props.productStore?.products?.find(product => product.id === selectedStampCardId)
  const [uploadedImage, setUploadedImage] = useState(selectedStampCard?.images?.mainImage || "");

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    restaurantId: Yup.string().required(),
    title: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required')
    }),
    description: Yup.object({ 
      en: Yup.string().required('Required'),
      fi: Yup.string().required('Required')
    }),
    validity: Yup.object({ 
      duration: Yup.number().required('Required'),
      durationUnit: Yup.string().required('Required')
    }),
    rules: Yup.object({
      type: Yup.string().required('Required'),
      quantity: Yup.number().required('Required'),
      activation: Yup.number().required('Required')
    }),
    pricing: Yup.object({
      amount: Yup.number().required('Required'),
      currency: Yup.string().required('Required'),
      vatPercentage: Yup.number().required('Required'),
    }),
    images: Yup.object({           
      mainImage: Yup.string(),
    }),
    visible: Yup.boolean().required('Required')
  });

  const {t, userSessionStore, productStore, history, api} = props

  const getVenueIDFromURL = () => {
    const urlVars = window.location.pathname.split('/')
    if (urlVars.length >= 5 && urlVars[3] === 'venue') {
      return urlVars[4]
    } else {
      return ''
    }
  }

  

  const venueId = getVenueIDFromURL()

  const initValues = selectedStampCard ? {
    id: selectedStampCard.id,
    restaurantId: selectedStampCard.restaurantId.id,
    description: { 
      "en": selectedStampCard.description.en,
      "fi": selectedStampCard.description.fi
    },
    validity: { 
      duration: selectedStampCard.validity.duration,
      durationUnit: selectedStampCard.validity.durationUnit 
    },
    rules: {
      type: selectedStampCard.rules.type,
      quantity: selectedStampCard.rules.quantity,
      activation: selectedStampCard.rules.activation
    },
    title: {
      en: selectedStampCard.title.en,
      fi: selectedStampCard.title.fi
    },
    pricing: {
      amount: selectedStampCard?.pricing?.amount ? selectedStampCard.pricing.amount / 100 : 0,
      currency: selectedStampCard.pricing.currency,
      vatPercentage: selectedStampCard.pricing.vatPercentage
    },
    images: {           
      mainImage: selectedStampCard.images?.mainImage || ''
    },
    visible: selectedStampCard.visible
  } :{
    id: "NEW__",
    restaurantId: venueId,
    description: { 
      "en": "",
      "fi": ""
    },
    validity: { 
      duration: 730,
      durationUnit: "day" 
    },
    rules: {
      type: "PRODUCT",
      quantity: 10,
      activation: 300
    },
    title: {
      en: "",
      fi: ""
    },
    pricing: {
      amount: 1,
      currency: "eur",
      vatPercentage: 24.0
    },
    images: {           
      mainImage: ""
    },
    visible: true
  }

  const handleSaveAndPublish = async (products: any[], newProduct: boolean) => {
    const response = await api!.postProducts(products, undefined, CREATE_UPDATE_PRODUCT_ERROR)
    if (response && response.products.length > 0) {
      if (newProduct) {
        productStore?.addProduct(response.products[0])
      } else {
        productStore?.updateProduct(response.products[0])
      }
      history.push(ProductsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
    }
  }

  const getObjChangedValues = (values: any, initialValues: any) => {
    return Object
      .entries(values)
      .reduce((acc: any, [key, value]) => {
        const hasChanged = initialValues[key] !== value

        if (hasChanged) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  const IMAGE_UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    ERROR: "ERROR"
  }

  const handleUploadImage = async (imageBlob: any) => {
    setUploadedImage(IMAGE_UPLOAD_STATUS.UPLOADING)
    const imageFile = await urlToFile(imageBlob, 'image.jpeg', 'image/jpeg')
    const imageURL = await api!.uploadImage('venue/product/main', imageFile)
    if (imageURL) {
      setUploadedImage(imageURL)
    } else {
      setUploadedImage(IMAGE_UPLOAD_STATUS.ERROR)
    }
  }

  const MainImage = () => {
    if (uploadedImage === "") {
      return <Card className="croppedCover" style={{ border: `1px solid ${colors.stroke}`, backgroundColor: 'red'}}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.UPLOADING) {
      return <Skeleton height={198} width={330}/>
    } else if (uploadedImage === IMAGE_UPLOAD_STATUS.ERROR) {
      return <Card className="croppedCover">
        <div className="alert alert-danger mt-5" role="alert">
          {t('manageVenuesScreen.imageUploadFailed')}
        </div>
      </Card>
    } else {
      return <img className="croppedCover" src={uploadedImage} alt="logo"/>
    }
  }

  const onBackToRewards = (isFormEdited: boolean) => {
    if (isFormEdited)
      setShowGoBackModal(true)
    else
      history.push(RewardsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
  }
  
  return (
    <Container>
      <AlertMessage source={MODIFY_PRODUCT_ERROR} />

      <Modal show={showGoBackModal} centered onHide={() => {
        setShowGoBackModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t("rewardsScreen.modals.backToRewards.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("rewardsScreen.modals.backToRewards.description")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => {
            setShowGoBackModal(false)
          }}>
            {t("productModifyScreen.goBackPopup.cancel")}
          </Button>
          <Button variant="primary" onClick={() => {
            history.push(RewardsLocation.toUrl(userSessionStore!.selectedBranch!.urlFields()))
          }}>
            {t("rewardsScreen.modals.backToRewards.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          // const updatedFields = getObjChangedValues(values, initValues)
          const productData = {
            ...values,
            images: {           
              mainImage: uploadedImage || null
            },
            pricing: {
              ...values.pricing,
              amount: fullCurrencyUnitsToCents(values.pricing.amount)
            }
          }
          // if (!selectedVenue) venueData.country = "Finland"
          const isNewProduct = values.id === 'NEW__'
          handleSaveAndPublish([productData], isNewProduct)
        }}
      >
        {({ errors, touched, values, dirty, handleChange, resetForm, setFieldValue }) => {
          console.log("FORM", errors)
          return (
          <Form>
            <Modal show={showRevertModal} centered onHide={() => {
              setShowRevertModal(false)
            }}>
              <Modal.Header closeButton>
                <Modal.Title>{t("manageVenuesScreen.revertChangesPopup.title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("manageVenuesScreen.revertChangesPopup.description")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.cancel")}
                </Button>
                <Button variant="primary" onClick={() => {
                  resetForm()
                  // setUploadedImage(selectedVenue?.images?.logoImage || "")
                  setShowRevertModal(false)
                }}>
                  {t("manageVenuesScreen.revertChangesPopup.confirm")}
                </Button>
              </Modal.Footer>
            </Modal>
            <Row>
              <Col md={4}>
                <Button 
                  variant="secondary" 
                  className="font-weight-bold" 
                  onClick={() => onBackToRewards(dirty)}
                  >
                  <FontAwesomeIcon className="mr-2" icon={["fas", "reply-all"]}/>
                    {t("stampCardModifyScreen.backToRewards")}
                </Button>
              </Col>
              <Col md={8}>
                <div className="float-right">
                  <Button variant="outline-primary font-weight-bold" className="mr-3" onClick={() => setShowRevertModal(true)}>
                    <FontAwesomeIcon className="mr-2" icon={["fas", "trash-alt"]}/>
                      {t("manageVenuesScreen.revert")}
                  </Button>
                  <Button variant="outline-success greenInput" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </div>
                
              </Col>
            
            </Row>

            <Container>
              <Row className="justify-content-md-center mt-5 mb-4">
                <div style={{
                    textAlign: 'center'
                }}>
                <h2 className="p-0 m-0 mb-4 text-primary font-weight-bold">{t(`stampCardModifyScreen.formTitle`)}</h2>
                {t(`stampCardModifyScreen.formDescription`).split('\n').map((text: any) => 
                    <p className="p-0 m-0 font-size-medium">{text}</p>
                    )}
                </div>
              </Row>
            </Container>

            <Container className="p-2" style={{
                border: `1px solid ${colors.stroke}`,
                borderBottom: 'none',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}>
                <Row>
                    <div className="m-0 font-size-medium" style={{
                        paddingLeft: 30
                    }}>{t('stampCardModifyScreen.basics')}</div>
                </Row>
            </Container>

            <Container className="formContainer" style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
            }}>
              <Row>
                <Col md={6}>
                  <Field 
                    name="title.en"
                    render={() => (
                      <FormGroup controlId="title.en">
                        <FormLabel 
                          className={`textInputLabel ${(errors.title?.en && touched.title?.en) && (errors.title?.fi && touched.title?.fi) ? 'text-danger' : ''}`}>
                            {t(`stampCardModifyScreen.titleField`)}
                        </FormLabel>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-2">EN</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            placeholder={t(`stampCardModifyScreen.titlePlaceholderEN`)} 
                            type={'text'} 
                            isInvalid={errors.title?.en && touched.title?.en ? true : false} 
                            value={values.title.en} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.en && touched.title?.en? (
                          <small className="text-danger">{errors.title?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="title.fi"
                    render={() => (
                      <FormGroup controlId="title.fi">
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text className="pr-3">FI</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl 
                            className="textInput" 
                            placeholder={t(`stampCardModifyScreen.titlePlaceholderFI`)} 
                            type={'text'} 
                            isInvalid={errors.title?.fi && touched.title?.fi ? true : false} 
                            value={values.title?.fi} 
                            onChange={handleChange} />
                        </InputGroup>
                        
                        {errors.title?.fi && touched.title?.fi ? (
                          <small className="text-danger">{errors.title?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.en"
                    render={() => (
                      <FormGroup controlId="description.en">
                        <FormLabel className={`textInputLabel ${errors.description?.en && touched.description?.en ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInEN`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.en && touched.description?.en ? true : false} 
                          placeholder={t(`productModifyScreen.descriptionInEN`)}
                          value={values.description?.en} 
                          onChange={handleChange}/>
                        {errors.description?.en && touched.description?.en ? (
                          <small className="text-danger">{errors.description?.en}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Field 
                    name="description.fi"
                    render={() => (
                      <FormGroup controlId="description.fi">
                        <FormLabel className={`textInputLabel ${errors.description?.fi && touched.description?.fi ? 'text-danger' : ''}`}>{t(`productModifyScreen.descriptionInFI`)}</FormLabel>
                        <FormControl 
                          className="textInput" 
                          as='textarea' 
                          rows={4} 
                          isInvalid={errors.description?.fi && touched.description?.fi ? true : false} 
                          placeholder={t(`cardModifyScreen.descriptionPlaceholderFI`)}
                          value={values.description?.fi} 
                          onChange={handleChange}/>
                        {errors.description?.fi && touched.description?.fi ? (
                          <small className="text-danger">{errors.description?.fi}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>

              <ImageUploadModal 
                show={showUploadStampCardPhotoModal}
                imageURL={values.images.mainImage}
                aspectRatio={1500 / 900}
                cropShape="rect"
                onCroppedImage={(croppedImage) => {
                  setCroppedStampCardPhoto(croppedImage)
                }}
                onCancel={() => {
                  setShowUploadStampCardPhotoModal(false)
                }}
                onSave={() => {
                  setShowUploadStampCardPhotoModal(false)
                  handleUploadImage(croppedStampCardPhoto)
                }}/>

              {/* <Row>
                <Col md={6}>
                  <Field 
                    name="images.mainImage"
                    render={() => (
                      <FormGroup controlId="images.mainImage" >
                        <h6 className={`mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`stampCardModifyScreen.rewardPhoto`)}</h6>
                        <div className="mb-2">
                          <small>{t('productModifyScreen.productPhotoDescription')}</small>
                        </div>

                        <label className="btn btn-secondary">
                            {t(`manageVenuesScreen.uploadPhoto`)}
                            <input 
                              type="file" 
                              id="images.mainImage"
                              hidden
                              name="file"
                              onChange={(event) => {
                                  if (event.currentTarget && event.currentTarget.files) {
                                    setFieldValue("images.mainImage", URL.createObjectURL(event.currentTarget.files[0]));
                                    setShowUploadStampCardPhotoModal(true)
                                  }
                              }} />
                        </label>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row> */}

              {/* <Row style={{height: 200}}>
                <Col md={4}>
                  <MainImage/>
                </Col>
              </Row> */}

              {/* <Row>
                <Col md={6}>
                <h6 className={`mt-3 mb-0 ${errors.images?.mainImage && touched.images?.mainImage ? 'text-danger' : ''}`}>{t(`productModifyScreen.numberOfProducts`)}</h6>
                    <div className="mb-2">
                      <small>{t('productModifyScreen.numberOfProductsDescription')}</small>
                    </div>
                </Col>
              </Row> */}

              {/* <Row>
                <Col md={2}>
                  <Field 
                    name="rules.quantity"
                    render={() => (
                      <FormGroup controlId="rules.quantity" >
                        <FormControl className="textInput text-center primaryInput" type={'number'} isInvalid={errors.rules?.quantity && touched.rules?.quantity ? true : false} value={values.rules?.quantity} onChange={(e) => setFieldValue('rules.quantity', parseInt(e.target.value).toFixed())} />
                        {errors.rules?.quantity && touched.rules?.quantity ? (
                          <small className="text-danger">{errors.rules?.quantity}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row> */}

              {/* <Row className="align-items-center">
                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                      <h6 className={`mt-3 mb-0 ${errors.pricing?.amount && touched.pricing?.amount ? 'text-danger' : ''}`}>{t(`productModifyScreen.totalPrice`)}</h6>
                          <div className="mb-2">
                            <small>{t('productModifyScreen.totalPriceDescription')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                      <Field 
                        name="pricing.amount"
                        render={() => (
                          <FormGroup controlId="pricing.amount" >
                            <InputGroup>
                              <FormControl className="textInput text-center primaryInput" type={'number'} isInvalid={errors.pricing?.amount && touched.pricing?.amount ? true : false} value={values.pricing?.amount} onChange={handleChange} />
                                {errors.pricing?.amount && touched.pricing?.amount ? (
                                  <small className="text-danger">{errors.pricing?.amount}</small>
                                ) : null}
                              <InputGroup.Append>
                                <InputGroup.Text className="primaryInputGroupText">€</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            
                          </FormGroup>
                        )}
                      />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={3}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                      <h6 className={`invisible mt-3 mb-0 ${errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? 'text-danger' : ''}`}>{t(`productModifyScreen.vat`)}</h6>
                          <div className="mb-2">
                            <small>{t('productModifyScreen.vat')}</small>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <Field 
                          name="pricing.vatPercentage"
                          render={() => (
                            <FormGroup controlId="pricing.vatPercentage" >
                              <InputGroup>
                                <FormControl className="textInput text-center primaryInput" type={'number'} min={0} isInvalid={errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? true : false} value={values.pricing?.vatPercentage} onChange={handleChange} />
                                {errors.pricing?.vatPercentage && touched.pricing?.vatPercentage ? (
                                  <small className="text-danger">{errors.pricing?.vatPercentage}</small>
                                ) : null}

                                <InputGroup.Append>
                                  <InputGroup.Text className="primaryInputGroupText">%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={5}>
       
                </Col>
              </Row> */}

                <Row className="align-items-center">
                <Col md={4}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                      <div className="mb-2">
                      <div style={{
                        fontSize: 14
                      }}>{t('stampCardModifyScreen.euroEqualsPoints')}</div>
                    </div> 
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                      <Field 
                    name="validity.duration"
                    render={() => (
                      <FormGroup controlId="validity.duration">
                        <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.validity?.duration && touched.validity?.duration ? true : false} value={values.validity?.duration || 0} onChange={handleChange}>
                          <option value="100">100</option>
                          <option value="50">50</option>
                          <option value="10">10</option>
                        </FormControl>
                        {errors.validity?.duration && touched.validity?.duration ? (
                          <small className="text-danger">{errors.validity?.duration}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col md={3}>
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                          <div className="mb-2">
                            <div style={{
                                 fontSize: 14
                            }}>{t('stampCardModifyScreen.stampExpirationMonths')}</div>
                          </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                      <Field 
                    name="validity.duration"
                    render={() => (
                      <FormGroup controlId="validity.duration">
                        <FormControl className="textInput text-center primaryInput" as='select' isInvalid={errors.validity?.duration && touched.validity?.duration ? true : false} value={values.validity?.duration || 0} onChange={handleChange}>
                          <option value="730">24</option>
                          <option value="365">12</option>
                          <option value="180">6</option>
                        </FormControl>
                        {errors.validity?.duration && touched.validity?.duration ? (
                          <small className="text-danger">{errors.validity?.duration}</small>
                        ) : null}
                      </FormGroup>
                    )}
                  />
                      </Col>
                    </Row>
                  </Container>
                </Col>

              </Row>

              
              <Row>
                <Col md={6}>
                  <Button variant="outline-success greenInput mt-4" type="submit" className="font-weight-bold">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "cloud-arrow-up"]}/>
                    {t("manageVenuesScreen.saveAndPublish")}
                  </Button>
                </Col>
              </Row>

            </Container>
          </Form>
        )}}
      </Formik>
    </Container>
  )
}

export default withTranslation()(inject("userSessionStore", "api", "productStore")(observer(CreateUpdateStampCardScreen)))
